import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Header from '../Header/component';
import { updateProfile, TwoFactorAuth, getUserDetails } from './userApi';
// import ChatbotWeb from '../../components/chatbot/ChatbotWeb';
import { useParams } from 'react-router-dom';
// import ChatbotMobile from '../../components/chatbot/ChatbotMobile';

import {
  addUserEmail,
  updateUserName,
  selectUserDetails,
  updateTwoFA,
  getTwoFa,
  setUserdetails,
  updateAccounts,
  selectAccounts,
  getDefaultAccount
} from 'features/login/loginSlice';
import './styles.scss';
import moment from 'moment-timezone';
import { message } from 'antd';
import eyeShow from '../../assets/Img/eye-show.svg';
import eyeHide from '../../assets/Img/eye-hide.svg';
import Select from 'react-select';
import AppConfig from '../../config';
import axios from 'axios';
import { countryList } from './countryList';
import * as Yup from 'yup';
import Loader from 'components/loader/loader';
import { getTradingPlan } from '../../components/chatbot/tradingPlanSlice';

const FaAccounts = () => {
  const dispatch = useDispatch();
  const Userdetails = useSelector(selectUserDetails);
  const twoFA = useSelector(getTwoFa);
  const { user } = Userdetails;

  const getTimeZoneWithOffset = (tz) => {
    const offset = moment.tz(tz).format('Z');
    return `(GMT${offset}) ${tz}`;
  };

  const [userData, setUserData] = useState({
    first_name: user?.first_name ?? '',
    last_name: user?.last_name ?? '',
    street_address: user?.street_address ?? '',
    city: user?.city ?? '',
    state: user?.state ?? '',
    postal_code: user?.postal_code ?? '',
    country: user?.country ?? '',
    phone: user?.phone ?? '',
    email: user?.email ?? '',
    timezone: user?.timezone ?? ''
  });

  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedTimezone, setSelectedTimezone] = useState('');

  const [selectedLogin, setSelectedLogin] = useState('');

  const [loading, setLoading] = useState(false);
  const [pwdLoading, setPwdLoading] = useState(false);
  const [error, setError] = useState('');
  const [hasChanges, setHasChanges] = useState(false);
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [passwordChanged, setPasswordChanged] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [is2FAEnabled, setIs2FAEnabled] = useState(twoFA ? twoFA : false);
  const DefaultAccount = useSelector(getDefaultAccount);
  const [selectedAccount, setSelectedAccount] = useState(
    DefaultAccount ? DefaultAccount : null
  );
  const allAccounts = useSelector(selectAccounts);
  const { currentAccount } = useSelector((state) => state.chatbot);
  let { accountId } = useParams();
  const accounts = allAccounts?.filter(
    (account) => account.status === 'active'
  );
  const activeAccounts = accounts.filter(
    (account) => account.status === 'active'
  );

  useEffect(() => {
    if (currentAccount !== '') {
      dispatch(getTradingPlan(currentAccount));
    }
  }, [currentAccount, dispatch]);

  useEffect(() => {
    const accountId = DefaultAccount ?? accounts[0]?.account_id;
    const selected_account = accounts.find(
      (account) => account.account_id === accountId
    );
    if (selected_account) {
      setSelectedLogin(selected_account.login);
    }
  }, [DefaultAccount, accountId, accounts, selectedAccount]);

  useEffect(() => {
    if (user === undefined) {
      fetchUser();
    }
  }, []);
  const fetchUser = async () => {
    try {
      const data = await getUserDetails();
      setUserData({
        first_name: data?.user?.first_name,
        last_name: data?.user?.last_name,
        street_address: data?.user?.street_address,
        city: data?.user?.city,
        state: data?.user?.state,
        postal_code: data?.user?.postal_code,
        country: data?.user?.country,
        phone: data?.user?.phone,
        email: data?.user?.email,
        timezone: data?.user?.timezone
      });
      dispatch(setUserdetails(data));
      dispatch(updateAccounts(data?.accounts || []));
    } catch (error) {}
  };

  const passwordSchema = Yup.object().shape({
    newPassword: Yup.string()
      .required('New Password is required')
      .test(
        'password',
        'Password must not contain spaces',
        (value) => value && !/\s/.test(value)
      )
      .test(
        'password',
        'Password must contain at least one lowercase letter',
        (value) => /[a-z]/.test(value)
      )
      .test(
        'password',
        'Password must contain at least one uppercase letter',
        (value) => /[A-Z]/.test(value)
      )
      .test('password', 'Password must contain at least one number', (value) =>
        /\d/.test(value)
      )
      .test(
        'password',
        'Password must contain at least one special character',
        (value) => /[@$!%*?&]/.test(value)
      )
      .test(
        'password',
        'Password must be at least 8 characters long',
        (value) => value.length >= 8
      )
  });

  const setUserDataAndMarkChanges = (newData) => {
    setUserData(newData);
    setHasChanges(true);
  };

  const handleSelectCountry = (event) => {
    setHasChanges(true);
    setUserData({ ...userData, country: event.target.value });
    setSelectedCountry(event.target.value);
  };

  const handleSelectTimezone = (event) => {
    setHasChanges(true);
    setUserData({ ...userData, timezone: event.target.value });
    setSelectedTimezone(event.target.value);
  };

  const confirmPasswordChange = async () => {
    try {
      setPwdLoading(true);
      const token = localStorage.getItem('jwtToken');
      await passwordSchema.validate({ newPassword });

      const response = await axios.post(
        `${AppConfig.apiUrl}/update_password`,
        {
          old_password: oldPassword,
          new_password: newPassword
        },
        {
          headers: {
            Authorization: `${token}`,
            'Content-Type': 'application/json'
          }
        }
      );

      if (response.data.success) {
        message.success({
          key: 'passwordUpdated',
          content: response?.data.message
        });
        setOldPassword('');
        setNewPassword('');
        setPasswordError('');
        setPasswordChanged(false);
      } else {
        setPasswordError(response.data.message);
      }
    } catch (error) {
      setPasswordError(error.response?.data?.message || error.message);
    } finally {
      setPwdLoading(false);
    }
  };

  const updateUser = async () => {
    try {
      setLoading(true);
      setError('');
      const {
        token,
        account_ids,
        is_Agreed_To_Terms,
        is_news_letter_subscribed,
        password,
        ...user
      } = userData;
      const { _id: user_id, first_name, last_name, ...otherUserData } = user;

      if (!first_name.trim() || !last_name.trim()) {
        setError('First name and last name cannot be empty.');
        setLoading(false);
        return;
      }
      const response = await updateProfile(user_id, {
        first_name,
        last_name,
        ...otherUserData
      });
      if (response?.success) {
        const data = await getUserDetails();
        message.success({ key: 'userUpdated', content: response?.message });
        dispatch(setUserdetails(data));
        dispatch(updateUserName(first_name));
        dispatch(addUserEmail(otherUserData?.email || ''));
        setLoading(false);
      }
    } catch (error) {
      setError(error ? error : 'Error updating profile. Please try again.');
      setLoading(false);
    }
  };

  const handleToggleChange = async () => {
    try {
      const res = await TwoFactorAuth();
      if (res?.success) {
        dispatch(updateTwoFA(res?.value));
        setIs2FAEnabled(!is2FAEnabled);
      }
    } catch (error) {}
  };

  return (
    <>
      <div className="d-flex">
        <div
          className="left_container"
          style={{ position: 'relative', minHeight: '100vh', width: '100%' }}
        >
          <>
            <div className="main--container">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-xl-12 col-lg-12 p-0">
                    <div className='header-full-width'>
                    <Header />
                    </div>
                    <>
                      {user === undefined ? (
                        <Loader />
                      ) : (
                        <div className="table-container">
                          <div className="table-sub-container">
                            <div className="fa-filter-block">
                              <div className="fa-filter">
                                <h6>Profile</h6>
                              </div>
                            </div>
                            <div className="account-details">
                              <div className="analysis-data-section">
                                <div class="accordion" id="accordionExample">
                                  <div class="accordion-item">
                                    <h2
                                      class="accordion-header"
                                      id="headingOne"
                                    >
                                      <button
                                        class="accordion-button"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseOne"
                                        aria-expanded="true"
                                        aria-controls="collapseOne"
                                      >
                                        Account Details
                                      </button>
                                    </h2>
                                    <div
                                      id="collapseOne"
                                      class="accordion-collapse collapse show"
                                      aria-labelledby="headingOne"
                                      data-bs-parent="#accordionExample"
                                    >
                                      <div class="accordion-body">
                                        <form>
                                          <div className="row">
                                            <div className="col-lg-6 mb-2">
                                              <label className="form-label">
                                                First Name
                                              </label>
                                              <input
                                                type="text"
                                                className="form-control"
                                                value={userData.first_name}
                                                onChange={(e) => {
                                                  const newValue =
                                                    e.target.value.replace(
                                                      /[^A-Za-z]/g,
                                                      ''
                                                    );
                                                  setUserDataAndMarkChanges({
                                                    ...userData,
                                                    first_name: newValue
                                                  });
                                                }}
                                              />
                                            </div>
                                            <div className="col-lg-6 mb-2">
                                              <label className="form-label">
                                                Last Name
                                              </label>
                                              <input
                                                type="text"
                                                className="form-control"
                                                value={userData.last_name}
                                                onChange={(e) => {
                                                  const newValue =
                                                    e.target.value.replace(
                                                      /[^A-Za-z]/g,
                                                      ''
                                                    );

                                                  setUserDataAndMarkChanges({
                                                    ...userData,
                                                    last_name: newValue
                                                  });
                                                }}
                                              />
                                            </div>

                                            <div className="col-12 mb-2">
                                              <label className="form-label">
                                                Street Address
                                              </label>
                                              <input
                                                type="text"
                                                className="form-control"
                                                value={userData.street_address}
                                                onChange={(e) =>
                                                  setUserDataAndMarkChanges({
                                                    ...userData,
                                                    street_address:
                                                      e.target.value
                                                  })
                                                }
                                              />
                                            </div>
                                            <div className="col-lg-6 mb-2">
                                              <label
                                                className="form-label"
                                                value={userData.city}
                                              >
                                                City
                                              </label>
                                              <input
                                                type="text"
                                                className="form-control"
                                                value={userData.city}
                                                onChange={(e) => {
                                                  const newValue =
                                                    e.target.value.replace(
                                                      /[^A-Za-z]/g,
                                                      ''
                                                    );
                                                  setUserDataAndMarkChanges({
                                                    ...userData,
                                                    city: newValue
                                                  });
                                                }}
                                              />
                                            </div>
                                            <div className="col-lg-6 mb-2">
                                              <label
                                                className="form-label"
                                                value={userData.city}
                                              >
                                                State
                                              </label>
                                              <input
                                                type="text"
                                                className="form-control"
                                                value={userData.state}
                                                onChange={(e) => {
                                                  const newValue =
                                                    e.target.value.replace(
                                                      /[^A-Za-z]/g,
                                                      ''
                                                    );
                                                  setUserDataAndMarkChanges({
                                                    ...userData,
                                                    state: newValue
                                                  });
                                                }}
                                              />
                                            </div>
                                            <div className="col-lg-6 mb-2">
                                              <label className="form-label">
                                                Postal Code
                                              </label>
                                              <input
                                                type="text"
                                                className="form-control"
                                                value={userData.postal_code}
                                                onChange={(e) => {
                                                  const inputValue =
                                                    e.target.value;

                                                  const sanitizedValue =
                                                    inputValue
                                                      .replace(/\D/g, '')
                                                      .slice(0, 6);
                                                  setUserDataAndMarkChanges({
                                                    ...userData,
                                                    postal_code: sanitizedValue
                                                  });
                                                }}
                                              />
                                            </div>

                                            <div className="col-lg-6 mb-2">
                                              <label className="form-label">
                                                Country
                                              </label>
                                              <select
                                                value={
                                                  selectedCountry
                                                    ? selectedCountry
                                                    : userData.country
                                                }
                                                onChange={handleSelectCountry}
                                                className="form-select"
                                              >
                                                <option value="" disabled>
                                                  Select a country
                                                </option>
                                                {countryList.map((country) => (
                                                  <option
                                                    key={country.id}
                                                    value={country.name}
                                                  >
                                                    {country.name}
                                                  </option>
                                                ))}
                                              </select>
                                            </div>
                                            <div className="col-lg-6 mb-2">
                                              <label className="form-label">
                                                Phone
                                              </label>
                                              <input
                                                type="text"
                                                className="form-control"
                                                value={userData.phone}
                                                onChange={(e) => {
                                                  const inputText =
                                                    e.target.value;
                                                  const sanitizedInput =
                                                    inputText.replace(
                                                      /\D/g,
                                                      ''
                                                    );
                                                  const truncatedInput =
                                                    sanitizedInput.slice(0, 10);
                                                  setUserDataAndMarkChanges({
                                                    ...userData,
                                                    phone: truncatedInput
                                                  });
                                                }}
                                              />
                                            </div>
                                            <div className="col-lg-6 mb-2">
                                              <label className="form-label">
                                                Email
                                              </label>
                                              <input
                                                type="text"
                                                className="form-control"
                                                value={userData.email}
                                                onChange={(e) =>
                                                  setUserDataAndMarkChanges({
                                                    ...userData,
                                                    email: e.target.value
                                                  })
                                                }
                                              />
                                            </div>
                                            <div className="col-12 mb-2">
                                              <label className="form-label">
                                                Timezone
                                              </label>
                                              <Select
                                                options={moment.tz
                                                  .names()
                                                  .map((tz) => ({
                                                    value: tz,
                                                    label:
                                                      getTimeZoneWithOffset(tz)
                                                  }))}
                                                value={{
                                                  value: userData?.timezone,
                                                  label: getTimeZoneWithOffset(
                                                    userData?.timezone
                                                  )
                                                }}
                                                onChange={(selectedOption) => {
                                                  handleSelectTimezone({
                                                    target: {
                                                      value:
                                                        selectedOption.value
                                                    }
                                                  });
                                                }}
                                                isSearchable={true}
                                                placeholder="Timezone"
                                              />
                                            </div>
                                          </div>
                                        </form>
                                        {typeof error === 'string' && (
                                          <div className="d-flex justify-content-center mt-2 text-danger">
                                            {error}
                                          </div>
                                        )}

                                        <div className="d-flex justify-content-center mt-2">
                                          <button
                                            className="btn btn-primary btn-sm btn-xll"
                                            onClick={updateUser}
                                            disabled={!hasChanges || loading}
                                          >
                                            {loading
                                              ? 'Please wait...'
                                              : 'Save'}
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="accordion-item">
                                    <h2
                                      class="accordion-header"
                                      id="headingTwo"
                                    >
                                      <button
                                        class="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseTwo"
                                        aria-expanded="false"
                                        aria-controls="collapseTwo"
                                      >
                                        Password Change
                                      </button>
                                    </h2>

                                    <div
                                      id="collapseTwo"
                                      class="accordion-collapse collapse"
                                      aria-labelledby="headingTwo"
                                      data-bs-parent="#accordionExample"
                                    >
                                      <div class="accordion-body">
                                        <div class="mb-3">
                                          <label
                                            for="oldPassword"
                                            class="form-label"
                                          >
                                            Old Password:
                                          </label>
                                          <div className="password-changes">
                                            <input
                                              type={
                                                showOldPassword
                                                  ? 'text'
                                                  : 'password'
                                              }
                                              id="oldPassword"
                                              name="oldPassword"
                                              class="form-control"
                                              value={oldPassword}
                                              onChange={(e) => {
                                                setOldPassword(e.target.value);
                                                setPasswordError('');
                                                setPasswordChanged(true);
                                              }}
                                            />
                                            <button
                                              type={
                                                showOldPassword
                                                  ? 'text'
                                                  : 'password'
                                              }
                                              className="password-toggle-btn"
                                              style={{
                                                border: 'none',
                                                width: '50px',
                                                height: '35px',
                                                cursor: 'pointer',
                                                marginLeft: '-50px',
                                                fontSize: '16px',
                                                background: 'none'
                                              }}
                                              onClick={() =>
                                                setShowOldPassword(
                                                  !showOldPassword
                                                )
                                              }
                                            >
                                              <img
                                                src={
                                                  showOldPassword
                                                    ? eyeShow
                                                    : eyeHide
                                                }
                                                alt=""
                                              />
                                            </button>
                                          </div>
                                        </div>
                                        <div class="mb-3">
                                          <label
                                            for="newPassword"
                                            class="form-label"
                                          >
                                            New Password:
                                          </label>
                                          <div className="password-changes">
                                            <input
                                              type={
                                                showNewPassword
                                                  ? 'text'
                                                  : 'password'
                                              }
                                              id="newPassword"
                                              name="newPassword"
                                              class="form-control"
                                              value={newPassword}
                                              onChange={(e) => {
                                                setNewPassword(e.target.value);
                                                setPasswordError('');
                                                setPasswordChanged(true);
                                              }}
                                            />
                                            <button
                                              type={
                                                showNewPassword
                                                  ? 'text'
                                                  : 'password'
                                              }
                                              className="password-toggle-btn"
                                              style={{
                                                border: 'none',
                                                width: '50px',
                                                height: '35px',
                                                cursor: 'pointer',
                                                marginLeft: '-50px',
                                                fontSize: '16px',
                                                background: 'none'
                                              }}
                                              onClick={() =>
                                                setShowNewPassword(
                                                  !showNewPassword
                                                )
                                              }
                                            >
                                              <img
                                                src={
                                                  showNewPassword
                                                    ? eyeShow
                                                    : eyeHide
                                                }
                                                alt=""
                                              />
                                            </button>
                                          </div>

                                          {passwordError && (
                                            <div className="text-danger">
                                              {passwordError}
                                            </div>
                                          )}
                                        </div>
                                        <div className="d-flex justify-content-center mt-2">
                                          <button
                                            className="btn btn-primary btn-sm btn-xll"
                                            onClick={confirmPasswordChange}
                                            disabled={
                                              !passwordChanged || pwdLoading
                                            }
                                          >
                                            {pwdLoading
                                              ? 'Please wait...'
                                              : 'Save'}
                                          </button>
                                        </div>
                                        {successMessage && (
                                          <div className="text-success mt-2">
                                            {successMessage}
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>

                                  {/* <div class="accordion-item">
                                <h2 class="accordion-header" id="headingThree">
                                  <button
                                    class="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseThree"
                                    aria-expanded="false"
                                    aria-controls="collapseThree"
                                  >
                                    Two-Factor Authentication
                                  </button>
                                </h2>
                                <div
                                  id="collapseThree"
                                  class="accordion-collapse collapse"
                                  aria-labelledby="headingThree"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div class="accordion-body">
                                    <div className="authentication-body">
                                      <h5>Two-Factor Authentication</h5>
                                      <div className="enable-button">
                                        <p>Enable Two-Factor Authentication</p>
                                        <input
                                          type="checkbox"
                                          id="switch"
                                          checked={is2FAEnabled}
                                          onChange={handleToggleChange}
                                        />
                                        <label for="switch">Toggle</label>
                                      </div>
                                      <p className="mb-3">
                                        Increase security by enabling Two-Factor
                                        Authentication (2FA). A verification
                                        code will be sent to your email to
                                        confirm your identity when logging in.
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {/* <ChatbotMobile
                        selectedLogin={selectedLogin}
                        activeAccounts={activeAccounts}
                      /> */}
                    </>

                    {/* <button
                      className="chat--icon"
                      type="button"
                      data-bs-toggle="offcanvas"
                      data-bs-target="#offcanvasRight"
                      aria-controls="offcanvasRight"
                    >
                      <img alt="chatIcon" src={chatIcon} />
                    </button>
                    <div
                      class="offcanvas offcanvas-end"
                      tabindex="-1"
                      id="offcanvasRight"
                      aria-labelledby="offcanvasRightLabel"
                      
                    >
                      <div class="offcanvas-header">
                        <button
                          type="button"
                          class="btn-close text-reset"
                          data-bs-dismiss="offcanvas"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div class="offcanvas-body">...</div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </>
        </div>
        {/* <div className="right_container">
          <ChatbotWeb
            from={'account'}
            selectedLogin={selectedLogin}
            activeAccounts={accounts}
          />
        </div> */}
      </div>
    </>
  );
};
export default FaAccounts;
