import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import NewUserAccount from '../../features/new-user-account/component';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement
} from 'chart.js';
import { useSelector, useDispatch } from 'react-redux';
import Header from '../Header/component';
import './styles.scss';
import {
  getAnalysisReport,
  tradingPlanUpdate,
  getUpcomingTrades
} from './analysisApi';
import {
  selectAccounts,
  setanalysisData,
  getAnalysisData,
  setUpcomingReportTrade,
  getUpcomingReportTrade,
  setUserdetails,
  updateAccounts,
  setDefaultAccount,
  getDefaultAccount,
  getAnalysisGraphData,
  analysisGraph
} from 'features/login/loginSlice';
import leftArrow from '../../assets/images/leftArrow.svg';
import rightArrow from '../../assets/images/rightArrow.svg';
import Loader from 'components/loader/loader';
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { getUserDetails } from 'features/profile-screen/userApi';
import ChatbotWeb from '../../components/chatbot/ChatbotWeb';
import ChatbotMobile from '../../components/chatbot/ChatbotMobile';
import {
  clearChatMessage,
  setCurrentAccount,
  setCurrentLogin,
  sendChatMessageThunk
} from '../../components/chatbot/ChatSlice';
import { getTradingPlan } from '../../components/chatbot/tradingPlanSlice';
import { io } from 'socket.io-client';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement);
const Helper = require('../../utils/helper');

const URL = 'wss://api.fractalalpha.com/deals';

const Analysis = () => {
  const location = useLocation();
  const accountId_success_report = location?.state;
  const analysisPage = location?.state?.analysisPage;
  const dispatch = useDispatch();
  const data = useSelector(getAnalysisData);
  const tradeData = useSelector(getAnalysisGraphData);

  const DefaultAccount = useSelector(getDefaultAccount);
  const [InitiateModal, setInitiateModal] = useState(true);
  const InitiateModaltoggle = () => setInitiateModal(!InitiateModaltoggle);
  const [IsNewUser, setNewUser] = useState(false);
  const [tradingPlan, setTradingPlan] = useState(null);
  let { accountId } = useParams();
  // const [data, setData] = useState({});
  const [loading, setLoading] = useState(analysisPage ? analysisPage : false);
  const [loading1, setLoading1] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState(
    DefaultAccount ? DefaultAccount : null
  );
  // const [tradeData, setTradeData] = useState(null);
  const [overallProfit, setOverallProfit] = useState(0);
  const [overallLoss, setOverallLoss] = useState(0);
  const upcomingReportTrade = useSelector(getUpcomingReportTrade);

  const allAccounts = useSelector(selectAccounts);
  const accounts = allAccounts?.filter(
    (account) => account.status === 'active'
  );
  const { currentAccount } = useSelector((state) => state.chatbot);

  const [updateDataLoading, setupdateDataLoading] = useState(false);
  const [updateTrandingPlanId, setUpdateTrandingPlanId] = useState(
    accountId ? accountId : accounts.length ? accounts[0]?.account_id : ''
  );
  const [updateTradingPlanButton, setUpdateTradingPlanButton] = useState(false);
  const [updateTradingPlan, setUpdateTradingPlan] = useState({
    avoid_day_of_week: '',
    avoid_time_of_day: '',
    daily_profit_target: 0,
    maximum_daily_loss: 0,
    maximum_position_size: 0,
    maximum_trades_per_day: 0,
    profit_target_per_trade: 0,
    stop_loss: 0
  });
  const [selectedLogin, setSelectedLogin] = useState('');

  useEffect(() => {
    setNewUser(Helper.checkIfNewUser(allAccounts));
  }, [allAccounts]);

  useEffect(() => {
    const accountId = DefaultAccount ?? accounts[0]?.account_id;
    const selected_account = accounts.find(
      (account) => account.account_id === accountId
    );
    if (selected_account) {
      setSelectedLogin(selected_account.login);
      dispatch(setCurrentAccount(accountId));
      dispatch(setCurrentLogin(selected_account.login));
    }
  }, [DefaultAccount, accountId, accounts, selectedAccount]);

  useEffect(() => {
    const socket = io(URL, {
      query: { login: selectedLogin },
      reconnectionAttempts: 5, // Attempt to reconnect up to 5 times
      reconnectionDelay: 2000 // Delay between reconnection attempts
    });

    socket.on('connect', () => {
      console.log('WebSocket connected');
    });

    socket.on('disconnect', () => {
      console.log('WebSocket disconnected');
    });

    socket.on('error', (error) => {
      console.error('WebSocket error:', error);
    });

    socket.on('new_trade', (trade) => {
      if (trade) {
        console.log('WebSocket calling...', trade);
        handleTradeMessage();
      }
    });

    return () => {
      socket.close();
      console.log('WebSocket connection closed');
    };
  }, [selectedLogin]);

  const handleTradeMessage = async () => {
    try {
      await dispatch(
        sendChatMessageThunk({
          account_id: selectedAccount,
          login: selectedLogin,
          message: 'trade message',
          name: '',
          type: 'bot',
          key: 'trade'
        })
      ).unwrap();
    } catch (err) {
      console.log('Failed to handle trade message');
    }
  };

  const handleAvoidDayChange = (e) => {
    setUpdateTradingPlan((prevState) => ({
      ...prevState,
      avoid_day_of_week: e.target.value
    }));
    setUpdateTradingPlanButton(true);
  };

  const handleAvoidTimeChange = (e) => {
    setUpdateTradingPlan((prevState) => ({
      ...prevState,
      avoid_time_of_day: e.target.value
    }));
    setUpdateTradingPlanButton(true);
  };

  const handleDecrease = (key) => {
    setUpdateTradingPlan((prevState) => {
      if (key === 'maximum_position_size') {
        let newval = Math.max(Math.round(prevState[key] * 100 - 1) / 100, 0);
        return {
          ...prevState,
          [key]: newval.toFixed(2)
        };
      } else if (key === 'maximum_trades_per_day') {
        // Ensure maximum_trades_per_day doesn't go below 0
        const newval = Math.max(prevState[key] - 1, 0);
        return {
          ...prevState,
          [key]: newval
        };
      } else {
        const newval = Number.isInteger(prevState[key])
          ? prevState[key] - 1
          : parseFloat((prevState[key] - 1).toFixed(2));
        return {
          ...prevState,
          [key]: newval
        };
      }
    });
    setUpdateTradingPlanButton(true);
  };

  const handleIncrease = (key) => {
    setUpdateTradingPlan((prevState) => {
      if (key === 'maximum_position_size') {
        const newval = Math.round(prevState[key] * 100 + 1) / 100;
        return {
          ...prevState,
          [key]: newval.toFixed(2)
        };
      } else {
        const newval = Number.isInteger(prevState[key])
          ? prevState[key] + 1
          : parseFloat((prevState[key] + 1.0).toFixed(2));
        return {
          ...prevState,
          [key]: newval
        };
      }
    });
    setUpdateTradingPlanButton(true);
  };

  const handleSelectChange = async (event) => {
    setLoading1(true);
    const selectedValue = event.target.value;
    setSelectedAccount(selectedValue);
    setUpdateTrandingPlanId(selectedValue);
    dispatch(setDefaultAccount(selectedValue));
    dispatch(clearChatMessage(true));
    if (accountId) {
      window.history.pushState({}, '', `/analysis/${selectedValue}`);
    }
    await fetchData(selectedValue);
    const res = await getUpcomingTrades(selectedValue);
    dispatch(setUpcomingReportTrade(res?.trades_to_completion));
    setLoading1(false);
  };

  const handleUpdateTradingPlan = async () => {
    try {
      setupdateDataLoading(true);
      const response = await tradingPlanUpdate(
        updateTrandingPlanId,
        updateTradingPlan
      );
      if (response.success) {
        fetchData(updateTrandingPlanId);
        setUpdateTradingPlanButton(false);
      }
      setupdateDataLoading(false);
    } catch (error) {
      console.log('errorr', error);
    }
  };

  const fetchData = async (
    account_id = DefaultAccount ?? accounts[0]?.account_id
  ) => {
    try {
      const accountId = account_id ? account_id : accountId_success_report;
      const response = await getAnalysisReport(accountId);
      if (response) {
        const res = await getUpcomingTrades(accountId);
        dispatch(setUpcomingReportTrade(res?.trades_to_completion));
        dispatch(setanalysisData(response.report));
      }

      const dataFromAPI = {
        avoid_day_of_week: response?.report.Trading_Plan?.avoid_day_of_week,
        avoid_time_of_day: response?.report.Trading_Plan?.avoid_time_of_day,
        daily_profit_target: response?.report.Trading_Plan?.daily_profit_target,
        maximum_daily_loss: response?.report.Trading_Plan?.maximum_daily_loss,
        maximum_position_size:
          response?.report.Trading_Plan?.maximum_position_size,
        maximum_trades_per_day:
          response?.report.Trading_Plan?.maximum_trades_per_day,
        profit_target_per_trade:
          response?.report.Trading_Plan?.profit_target_per_trade,
        stop_loss: response?.report.Trading_Plan?.stop_loss
      };
      setUpdateTradingPlan(dataFromAPI);
      setTradingPlan(response?.report.Trading_Plan);

      const tradeLabels = response?.report.Report?.graph?.dateSummary.map(
        (trade) => trade.date.split(' ')[0]
      );
      const tradeValues = response?.report.Report?.graph?.dateSummary.map(
        (trade) => trade.total_profit
      );

      // setTradeData({
      //   labels: tradeLabels,
      //   datasets: [
      //     {
      //       label: 'Current Profit',
      //       data: tradeValues,
      //       fill: true,
      //       borderColor: 'rgba(75, 192, 192, 1)',
      //       backgroundColor: 'rgba(75, 192, 192, 0.2)',
      //       borderWidth: 4,
      //       pointRadius: 0,
      //       tension: 0.2
      //     }
      //   ]
      // });
      dispatch(
        analysisGraph({
          labels: tradeLabels,
          datasets: [
            {
              label: 'Current Profit',
              data: tradeValues,
              fill: true,
              borderColor: 'rgba(75, 192, 192, 1)',
              backgroundColor: 'rgba(75, 192, 192, 0.2)',
              borderWidth: 4,
              pointRadius: 0,
              tension: 0.2
            }
          ]
        })
      );
      let totalProfit = 0;
      let totalLoss = 0;
      tradeValues?.forEach((value) => {
        if (value > 0) {
          totalProfit += value;
        } else {
          totalLoss += Math.abs(value);
        }
      });

      setOverallProfit(totalProfit);
      setOverallLoss(totalLoss);
      setLoading(false);
    } catch (error) {
      // setData([]);
      dispatch(setUpcomingReportTrade(0));
      dispatch(setanalysisData({}));
      setLoading(false);
    }
  };

  const fetchUser = async () => {
    try {
      const data = await getUserDetails();
      const select = data?.accounts.filter(
        (account) => account.status === 'active'
      );
      if (!accountId) {
        setUpdateTrandingPlanId(select[0]?.account_id);
        fetchData(select[0]?.account_id);
      } else {
        dispatch(setDefaultAccount(accountId));
        fetchData(accountId);
      }
      dispatch(setUserdetails(data));
      dispatch(updateAccounts(data?.accounts || []));
    } catch (error) {}
  };

  useEffect(() => {
    if (accounts && accounts.length === 0) {
      fetchUser();
    } else {
      fetchData(accountId);
    }
    // eslint-diable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (currentAccount !== '') {
      dispatch(getTradingPlan(currentAccount));
    }
  }, [currentAccount, dispatch]);

  const handleInputChange = (key, value) => {
    const regex = /^\d{0,3}(\.\d{0,2})?$/;
    if (regex.test(value) || value === '') {
      setUpdateTradingPlan((prevState) => ({
        ...prevState,
        [key]: value
      }));
      setUpdateTradingPlanButton(true);
    }
  };

  return (
    <>
      <Header activeLink="analysis" />
      {IsNewUser ? (
        <NewUserAccount
          InitiateModal={InitiateModal}
          InitiateModaltoggle={InitiateModaltoggle}
        />
      ) : (
        <>
          <div className="main_page">
            <div
              className="left_container"
              style={{ position: 'relative', minHeight: '100vh' }}
            >
              <div className="main--container">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-xl-12 col-lg-12 p-0">
                      {!data?.Report?.long_short_comparison?.description ||
                      loading1 ||
                      loading ? (
                        <Loader />
                      ) : (
                        <>
                          <div className="table-container">
                            <div className="table-sub-container">
                              <div className="fa-filter-block">
                                <div className="fa-filter">
                                  <h6>Account Analysis</h6>
                                  <div>
                                    <select
                                      className="form-select"
                                      onChange={handleSelectChange}
                                      value={selectedAccount}
                                      defaultValue={
                                        accountId ? accountId : DefaultAccount
                                      }
                                    >
                                      {accounts.map((account) => (
                                        <option
                                          value={account.account_id}
                                          key={account.account_id}
                                          selected={
                                            (DefaultAccount || accountId) ===
                                            account.account_id
                                          }
                                        >
                                          Account #{account.account_id}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </div>
                              </div>
                              <div className="account-analysis">
                                <nav>
                                  <div
                                    className="nav nav-tabs  justify-content-between gap-2"
                                    id="nav-tab"
                                    role="tablist"
                                  >
                                    <div className="d-flex align-items-center">
                                      <button
                                        className="nav-link active"
                                        id="nav-home-tab"
                                        data-bs-toggle="tab"
                                        data-bs-target="#nav-home"
                                        type="button"
                                        role="tab"
                                        aria-controls="nav-home"
                                        aria-selected="true"
                                      >
                                        Analysis
                                      </button>
                                      <button
                                        className="nav-link"
                                        id="nav-profile-tab"
                                        data-bs-toggle="tab"
                                        data-bs-target="#nav-profile"
                                        type="button"
                                        role="tab"
                                        aria-controls="nav-profile"
                                        aria-selected="false"
                                      >
                                        Trading Plan
                                      </button>
                                    </div>
                                    {data?.Report?.timeFrame?.upcoming && (
                                      <div className="report-list">
                                        <div className="report-title">
                                          Upcoming Report :
                                        </div>
                                        <div className="report-data">
                                          {upcomingReportTrade} Trades remaining
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </nav>
                                <div
                                  className="tab-content"
                                  id="nav-tabContent"
                                >
                                  <div
                                    className="tab-pane fade show active"
                                    id="nav-home"
                                    role="tabpanel"
                                    aria-labelledby="nav-home-tab"
                                  >
                                    <div className="row justify-content-between align-items-end mb-3">
                                      <div className="col-lg-5">
                                        <ul className="report-headings">
                                          {data?.Report?.timeFrame?.broker && (
                                            <li className="report-list">
                                              <div className="report-title">
                                                {data.Report.timeFrame.broker.toUpperCase()}{' '}
                                                :
                                              </div>
                                              <div className="report-data">
                                                {data.Report.timeFrame.login ??
                                                  'N/A'}
                                              </div>
                                            </li>
                                          )}
                                          {data?.Report?.timeFrame?.start &&
                                            data?.Report?.timeFrame?.end && (
                                              <li className="report-list">
                                                <div className="report-title">
                                                  Data Timeframe :
                                                </div>
                                                <div className="report-data">
                                                  {data.Report.timeFrame.start}{' '}
                                                  - {data.Report.timeFrame.end}
                                                </div>
                                              </li>
                                            )}
                                          {data?.Report?.timeFrame?.end && (
                                            <li className="report-list">
                                              <div className="report-title">
                                                Report Completed :
                                              </div>
                                              <div className="report-data">
                                                <span className="green">
                                                  {(() => {
                                                    const months = [
                                                      'January',
                                                      'February',
                                                      'March',
                                                      'April',
                                                      'May',
                                                      'June',
                                                      'July',
                                                      'August',
                                                      'September',
                                                      'October',
                                                      'November',
                                                      'December'
                                                    ];
                                                    const date = new Date(
                                                      data?.Report?.timeFrame?.report_completed
                                                    );
                                                    const monthName =
                                                      months[date.getMonth()];
                                                    const day = date.getDate();
                                                    const year =
                                                      date.getFullYear() % 100;
                                                    return `${day} ${monthName} ${year}`;
                                                  })()}
                                                </span>
                                              </div>
                                            </li>
                                          )}
                                        </ul>
                                        <div className="table-responsive">
                                          <table className="table">
                                            <thead>
                                              <tr>
                                                <th
                                                  colspan="2"
                                                  style={{
                                                    borderBottom:
                                                      '1px solid #000'
                                                  }}
                                                >
                                                  {' '}
                                                  Statistics{' '}
                                                </th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              <tr>
                                                <th>Win rate</th>
                                                <td>
                                                  {data?.Report?.statistics
                                                    ?.winRate ?? 'N/A'}
                                                </td>
                                              </tr>
                                              <tr>
                                                <th>Average profit</th>
                                                <td>
                                                  {data?.Report?.statistics
                                                    ?.avgProfit ?? 'N/A'}
                                                </td>
                                              </tr>
                                              <tr>
                                                <th>Average loss</th>
                                                <td>
                                                  {data?.Report?.statistics
                                                    ?.avgLoss ?? 'N/A'}
                                                </td>
                                              </tr>
                                              <tr>
                                                <th>RRR</th>
                                                <td>
                                                  {data?.Report?.statistics
                                                    ?.RRR !== undefined &&
                                                  data.Report.statistics.RRR !==
                                                    ''
                                                    ? data.Report.statistics.RRR
                                                    : 'N/A'}
                                                </td>
                                              </tr>
                                              <tr>
                                                <th>Max Profit</th>
                                                <td>
                                                  {data?.Report?.statistics
                                                    ?.maxProfit ?? 'N/A'}
                                                </td>
                                              </tr>
                                              <tr>
                                                <th>Max Loss</th>
                                                <td>
                                                  {data?.Report?.statistics
                                                    ?.maxLoss ?? 'N/A'}
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                      <div className="col-lg-6">
                                        <div className="analysis-graph">
                                          {tradeData !== null ? (
                                            <Line
                                              data={{
                                                labels: tradeData?.labels,
                                                datasets:
                                                  tradeData?.datasets?.map(
                                                    (dataset) => ({
                                                      ...dataset,
                                                      fill: true
                                                    })
                                                  )
                                              }}
                                              options={{
                                                scales: {
                                                  x: {
                                                    display: true
                                                  }
                                                },
                                                elements: {
                                                  line: {
                                                    tension: 0.4
                                                  }
                                                },
                                                responsive: true,
                                                maintainAspectRatio: false
                                                // width: 1000,
                                                // height: 850
                                              }}
                                            />
                                          ) : (
                                            <p>Loading chart data...</p>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="analysis-data-section">
                                      <div
                                        className="accordion"
                                        id="accordionPanelsStayOpenExample"
                                      >
                                        <div className="accordion-item">
                                          <h2
                                            className="accordion-header"
                                            id="headingOne"
                                          >
                                            <button
                                              className="accordion-button"
                                              type="button"
                                              data-bs-toggle="collapse"
                                              data-bs-target="#collapseOne"
                                              aria-expanded="true"
                                              aria-controls="collapseOne"
                                            >
                                              Long / Short comparison
                                            </button>
                                          </h2>
                                          <div
                                            id="collapseOne"
                                            className="accordion-collapse collapse show"
                                            aria-labelledby="headingOne"
                                            data-bs-parent="#accordionExample"
                                          >
                                            <div class="accordion-body">
                                              <p
                                                className="mb-3"
                                                style={{ color: '#000' }}
                                              >
                                                This data compares the
                                                performance of long and short
                                                trades, helping traders assess
                                                their profitability and risk for
                                                making informed trading
                                                decisions.
                                              </p>
                                              <div className="row">
                                                <div className="col-lg-7">
                                                  <div className="table-responsive">
                                                    <table className="table">
                                                      <thead>
                                                        <tr>
                                                          <th></th>
                                                          <th>Long</th>
                                                          <th>Short</th>
                                                        </tr>
                                                      </thead>
                                                      <tbody>
                                                        <tr>
                                                          <th>
                                                            Num. of trades
                                                          </th>
                                                          <td>
                                                            {data.Report
                                                              .long_short_comparison
                                                              .calculation.BUY[
                                                              'Num. of trades'
                                                            ]
                                                              ? data.Report
                                                                  .long_short_comparison
                                                                  .calculation
                                                                  .BUY[
                                                                  'Num. of trades'
                                                                ]
                                                              : 'N/A'}
                                                          </td>
                                                          <td>
                                                            {data.Report
                                                              .long_short_comparison
                                                              .calculation.SELL[
                                                              'Num. of trades'
                                                            ]
                                                              ? data.Report
                                                                  .long_short_comparison
                                                                  .calculation
                                                                  .SELL[
                                                                  'Num. of trades'
                                                                ]
                                                              : 'N/A'}
                                                          </td>
                                                        </tr>
                                                        <tr>
                                                          <th>Result</th>
                                                          <td>
                                                            {data.Report
                                                              .long_short_comparison
                                                              .calculation.BUY[
                                                              'Result'
                                                            ]
                                                              ? data.Report
                                                                  .long_short_comparison
                                                                  .calculation
                                                                  .BUY['Result']
                                                              : 'N/A'}
                                                          </td>
                                                          <td>
                                                            {data.Report
                                                              .long_short_comparison
                                                              .calculation.SELL[
                                                              'Result'
                                                            ]
                                                              ? data.Report
                                                                  .long_short_comparison
                                                                  .calculation
                                                                  .SELL[
                                                                  'Result'
                                                                ]
                                                              : 'N/A'}
                                                          </td>
                                                        </tr>
                                                        <tr>
                                                          <th>Win rate</th>
                                                          <td>
                                                            {data.Report
                                                              .long_short_comparison
                                                              .calculation.BUY[
                                                              'Win rate'
                                                            ]
                                                              ? data.Report
                                                                  .long_short_comparison
                                                                  .calculation
                                                                  .BUY[
                                                                  'Win rate'
                                                                ]
                                                              : 'N/A'}
                                                          </td>
                                                          <td>
                                                            {data.Report
                                                              .long_short_comparison
                                                              .calculation.SELL[
                                                              'Win rate'
                                                            ]
                                                              ? data.Report
                                                                  .long_short_comparison
                                                                  .calculation
                                                                  .SELL[
                                                                  'Win rate'
                                                                ]
                                                              : 'N/A'}
                                                          </td>
                                                        </tr>
                                                        <tr>
                                                          <th>
                                                            Average profit
                                                          </th>
                                                          <td>
                                                            {data.Report
                                                              .long_short_comparison
                                                              .calculation.BUY[
                                                              'Average profit'
                                                            ]
                                                              ? data.Report
                                                                  .long_short_comparison
                                                                  .calculation
                                                                  .BUY[
                                                                  'Average profit'
                                                                ]
                                                              : 'N/A'}
                                                          </td>
                                                          <td>
                                                            {data.Report
                                                              .long_short_comparison
                                                              .calculation.SELL[
                                                              'Average profit'
                                                            ]
                                                              ? data.Report
                                                                  .long_short_comparison
                                                                  .calculation
                                                                  .SELL[
                                                                  'Average profit'
                                                                ]
                                                              : 'N/A'}
                                                          </td>
                                                        </tr>
                                                        <tr>
                                                          <th>RRR</th>
                                                          <td>
                                                            {data.Report
                                                              .long_short_comparison
                                                              .calculation.BUY[
                                                              'RRR'
                                                            ]
                                                              ? data.Report
                                                                  .long_short_comparison
                                                                  .calculation
                                                                  .BUY['RRR']
                                                              : 'N/A'}
                                                          </td>
                                                          <td>
                                                            {data.Report
                                                              .long_short_comparison
                                                              .calculation.SELL[
                                                              'RRR'
                                                            ]
                                                              ? data.Report
                                                                  .long_short_comparison
                                                                  .calculation
                                                                  .SELL['RRR']
                                                              : 'N/A'}
                                                          </td>
                                                        </tr>
                                                      </tbody>
                                                    </table>
                                                  </div>
                                                  <ul
                                                    className="p-0 m-0"
                                                    style={{
                                                      listStyle: 'none'
                                                    }}
                                                  >
                                                    <li className="mb-2">
                                                      <b>Trade Frequency:</b>{' '}
                                                      {data.Report
                                                        .long_short_comparison
                                                        .description[
                                                        'Trade Frequency'
                                                      ]
                                                        ? data.Report
                                                            .long_short_comparison
                                                            .description[
                                                            'Trade Frequency'
                                                          ]
                                                        : 'N/A'}
                                                    </li>
                                                    <li className="mb-2">
                                                      <b>Profit Discrepancy:</b>{' '}
                                                      {data.Report
                                                        .long_short_comparison
                                                        .description[
                                                        'Profit Discrepancy'
                                                      ]
                                                        ? data.Report
                                                            .long_short_comparison
                                                            .description[
                                                            'Profit Discrepancy'
                                                          ]
                                                        : 'N/A'}
                                                    </li>
                                                    <li className="mb-2">
                                                      <b>Win Rates:</b>{' '}
                                                      {data.Report
                                                        .long_short_comparison
                                                        .description[
                                                        'Win Rates'
                                                      ]
                                                        ? data.Report
                                                            .long_short_comparison
                                                            .description[
                                                            'Win Rates'
                                                          ]
                                                        : 'N/A'}
                                                    </li>
                                                    <li className="mb-2">
                                                      <b>Average Profits:</b>{' '}
                                                      {data.Report
                                                        .long_short_comparison
                                                        .description[
                                                        'Average Profits'
                                                      ]
                                                        ? data.Report
                                                            .long_short_comparison
                                                            .description[
                                                            'Average Profits'
                                                          ]
                                                        : 'N/A'}
                                                    </li>
                                                    <li className="mb-2">
                                                      <b>
                                                        Risk-Reward Ratios
                                                        (RRR):
                                                      </b>{' '}
                                                      {data.Report
                                                        .long_short_comparison
                                                        .description[
                                                        'Risk-Reward Ratios (RRR)'
                                                      ]
                                                        ? data.Report
                                                            .long_short_comparison
                                                            .description[
                                                            'Risk-Reward Ratios (RRR)'
                                                          ]
                                                        : 'N/A'}
                                                    </li>
                                                    <li className="mb-2">
                                                      {data.Report
                                                        .long_short_comparison
                                                        .description[
                                                        'Conclusion'
                                                      ]
                                                        ? data.Report
                                                            .long_short_comparison
                                                            .description[
                                                            'Conclusion'
                                                          ]
                                                        : 'N/A'}
                                                    </li>
                                                  </ul>
                                                </div>
                                                <div className="col-lg-5">
                                                  <div className="suggestions">
                                                    <h6 className="dark-green">
                                                      Suggestions
                                                    </h6>
                                                    {data.Report
                                                      .long_short_comparison
                                                      .suggestion
                                                      ? data.Report.long_short_comparison.suggestion
                                                          .split('\n\n')
                                                          .map(
                                                            (
                                                              paragraph,
                                                              index
                                                            ) => (
                                                              <p key={index}>
                                                                {paragraph}
                                                              </p>
                                                            )
                                                          )
                                                      : 'N/A'}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="accordion-item">
                                          <h2
                                            className="accordion-header"
                                            id="headingTwo"
                                          >
                                            <button
                                              className="accordion-button"
                                              type="button"
                                              data-bs-toggle="collapse"
                                              data-bs-target="#collapseTwo"
                                              aria-expanded="true"
                                              aria-controls="collapseTwo"
                                            >
                                              Results by days
                                            </button>
                                          </h2>
                                          <div
                                            id="collapseTwo"
                                            className="accordion-collapse collapse show"
                                            aria-labelledby="headingTwo"
                                            data-bs-parent="#accordionExample"
                                          >
                                            <div class="accordion-body">
                                              <p
                                                className="mb-3"
                                                style={{ color: '#000' }}
                                              >
                                                This insights offer a
                                                day-specific breakdown of the
                                                trading results, enabling
                                                traders to identify which days
                                                of the week tend to be more
                                                profitable or challenging and
                                                make strategic decisions
                                                accordingly.
                                              </p>
                                              <div className="row">
                                                <div className="col-lg-7">
                                                  <div className="table-responsive">
                                                    <table className="table">
                                                      <thead>
                                                        <tr>
                                                          <th>Day</th>
                                                          <th>P&L</th>
                                                        </tr>
                                                      </thead>
                                                      <tbody>
                                                        <tr>
                                                          <th className="bold-text">
                                                            Monday
                                                          </th>
                                                          <td>
                                                            {data?.Report
                                                              ?.results_by_days
                                                              ?.calculation
                                                              ?.Monday?.p_l ??
                                                              'N/A'}
                                                          </td>
                                                        </tr>
                                                        <tr>
                                                          <th className="bold-text">
                                                            Tuesday
                                                          </th>
                                                          <td>
                                                            {data.Report
                                                              .results_by_days
                                                              .calculation
                                                              .Tuesday?.p_l ??
                                                              'N/A'}
                                                          </td>
                                                        </tr>
                                                        <tr>
                                                          <th className="bold-text">
                                                            Wednesday
                                                          </th>
                                                          <td>
                                                            {data.Report
                                                              .results_by_days
                                                              .calculation
                                                              .Wednesday?.p_l ??
                                                              'N/A'}
                                                          </td>
                                                        </tr>
                                                        <tr>
                                                          <th className="bold-text">
                                                            Thursday
                                                          </th>
                                                          <td>
                                                            {data.Report
                                                              .results_by_days
                                                              .calculation
                                                              .Thursday?.p_l ??
                                                              'N/A'}
                                                          </td>
                                                        </tr>
                                                        <tr>
                                                          <th className="bold-text">
                                                            Friday
                                                          </th>
                                                          <td>
                                                            {data.Report
                                                              .results_by_days
                                                              .calculation
                                                              .Friday?.p_l ??
                                                              'N/A'}
                                                          </td>
                                                        </tr>
                                                      </tbody>
                                                    </table>
                                                  </div>
                                                  <ul
                                                    className="p-0 m-0"
                                                    style={{
                                                      listStyle: 'none'
                                                    }}
                                                  >
                                                    <li className="mb-2">
                                                      <b>Monday:</b>{' '}
                                                      {data.Report
                                                        .results_by_days
                                                        .description.Monday ??
                                                        'N/A'}
                                                    </li>
                                                    <li className="mb-2">
                                                      <b>Tuesday:</b>{' '}
                                                      {data.Report
                                                        .results_by_days
                                                        .description.Tuesday ??
                                                        'N/A'}
                                                    </li>
                                                    <li className="mb-2">
                                                      <b>Wednesday:</b>{' '}
                                                      {data.Report
                                                        .results_by_days
                                                        .description
                                                        .Wednesday ?? 'N/A'}
                                                    </li>
                                                    <li className="mb-2">
                                                      <b>Thursday:</b>
                                                      {data.Report
                                                        .results_by_days
                                                        .description.Thursday ??
                                                        'N/A'}
                                                    </li>
                                                    <li className="mb-2">
                                                      <b>Friday:</b>{' '}
                                                      {data.Report
                                                        .results_by_days
                                                        .description.Friday ??
                                                        'N/A'}
                                                    </li>
                                                  </ul>
                                                </div>
                                                <div className="col-lg-5">
                                                  <div className="suggestions">
                                                    <h6 className="dark-green">
                                                      Suggestions
                                                    </h6>
                                                    {data?.Report
                                                      ?.results_by_days
                                                      ?.suggestion
                                                      ? data.Report.results_by_days.suggestion
                                                          .split('\n\n')
                                                          .map(
                                                            (
                                                              paragraph,
                                                              index
                                                            ) => (
                                                              <p key={index}>
                                                                {paragraph}
                                                              </p>
                                                            )
                                                          )
                                                      : 'N/A'}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="accordion-item">
                                          <h2
                                            className="accordion-header"
                                            id="headingThree"
                                          >
                                            <button
                                              className="accordion-button"
                                              type="button"
                                              data-bs-toggle="collapse"
                                              data-bs-target="#collapseThree"
                                              aria-expanded="true"
                                              aria-controls="collapseThree"
                                            >
                                              Results by Instruments
                                            </button>
                                          </h2>
                                          <div
                                            id="collapseThree"
                                            className="accordion-collapse collapse show"
                                            aria-labelledby="headingThree"
                                            data-bs-parent="#accordionExample"
                                          >
                                            <div class="accordion-body">
                                              <p
                                                className="mb-3"
                                                style={{ color: '#000' }}
                                              >
                                                These insights offer a summary
                                                of the cumulative trading
                                                performance, demonstrating that
                                                short trades proved more
                                                profitable than long trades for
                                                the specified instrument. This
                                                information underscores the
                                                historical profitability across
                                                all trades within this category.
                                              </p>
                                              <div className="table-responsive">
                                                <table className="table">
                                                  <thead>
                                                    <tr>
                                                      <th></th>
                                                      <th># of Trades</th>
                                                      <th>Results</th>
                                                      <th>
                                                        Long Trades
                                                        <br /> # of Trades
                                                      </th>
                                                      <th>Results</th>
                                                      <th>
                                                        Short Trades
                                                        <br /> # of Trades
                                                      </th>
                                                      <th>Results</th>
                                                    </tr>
                                                  </thead>
                                                  <tbody>
                                                    {Object.entries(
                                                      data?.Report
                                                        ?.results_by_instrument
                                                        ?.calculation ?? {}
                                                    ).map(
                                                      ([
                                                        instrument,
                                                        instrumentData
                                                      ]) => (
                                                        <tr key={instrument}>
                                                          <th>{instrument}</th>
                                                          <td>
                                                            {instrumentData?.[
                                                              'N. of Trades'
                                                            ] ?? 'N/A'}
                                                          </td>
                                                          <td>
                                                            {instrumentData?.Result ??
                                                              'N/A'}
                                                          </td>
                                                          <td>
                                                            {instrumentData?.[
                                                              'Long / Short comparison'
                                                            ]?.long?.[
                                                              'N. of Trades'
                                                            ] ?? 'N/A'}
                                                          </td>
                                                          <td>
                                                            {instrumentData?.[
                                                              'Long / Short comparison'
                                                            ]?.long?.Result ??
                                                              'N/A'}
                                                          </td>
                                                          <td>
                                                            {instrumentData?.[
                                                              'Long / Short comparison'
                                                            ]?.short?.[
                                                              'N. of Trades'
                                                            ] ?? 'N/A'}
                                                          </td>
                                                          <td>
                                                            {instrumentData?.[
                                                              'Long / Short comparison'
                                                            ]?.short?.Result ??
                                                              'N/A'}
                                                          </td>
                                                        </tr>
                                                      )
                                                    )}
                                                  </tbody>
                                                </table>
                                              </div>
                                              <div className="row">
                                                <div className="col-lg-5">
                                                  {Object.keys(
                                                    data.Report
                                                      .results_by_instrument
                                                      .description
                                                  ).map((instrument) => {
                                                    const description =
                                                      data.Report
                                                        .results_by_instrument
                                                        .description[
                                                        instrument
                                                      ];
                                                    if (description) {
                                                      const splitText =
                                                        description.split(
                                                          /\n\n|\n/
                                                        );

                                                      if (
                                                        splitText.length > 0 &&
                                                        splitText[
                                                          splitText.length - 1
                                                        ] === ''
                                                      ) {
                                                        splitText.pop();
                                                      }

                                                      return (
                                                        <ul
                                                          className="p-0 m-0"
                                                          style={{
                                                            listStyle: 'none'
                                                          }}
                                                          key={instrument}
                                                        >
                                                          <li className="mb-2">
                                                            <b>
                                                              {instrument} :
                                                            </b>
                                                            <br />
                                                            {splitText.map(
                                                              (item, index) => (
                                                                <ul
                                                                  className="instrument-text"
                                                                  key={index}
                                                                >
                                                                  <li>
                                                                    {item}
                                                                  </li>
                                                                </ul>
                                                              )
                                                            )}
                                                          </li>
                                                        </ul>
                                                      );
                                                    }
                                                  })}
                                                </div>
                                                <div className="col-lg-7">
                                                  <div className="suggestions">
                                                    <h6 className="dark-green">
                                                      Suggestions
                                                    </h6>
                                                    {data?.Report
                                                      ?.results_by_instrument
                                                      ?.suggestion
                                                      ? data.Report.results_by_instrument.suggestion
                                                          .split('\n\n')
                                                          .map(
                                                            (
                                                              paragraph,
                                                              index
                                                            ) => (
                                                              <p key={index}>
                                                                {paragraph}
                                                              </p>
                                                            )
                                                          )
                                                      : 'N/A'}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="accordion-item">
                                          <h2
                                            className="accordion-header"
                                            id="headingFour"
                                          >
                                            <button
                                              className="accordion-button"
                                              type="button"
                                              data-bs-toggle="collapse"
                                              data-bs-target="#collapseFour"
                                              aria-expanded="true"
                                              aria-controls="collapseFour"
                                            >
                                              Trading Days Analysis
                                            </button>
                                          </h2>
                                          <div
                                            id="collapseFour"
                                            className="accordion-collapse collapse show"
                                            aria-labelledby="headingFour"
                                            data-bs-parent="#accordionExample"
                                          >
                                            <div class="accordion-body">
                                              <p
                                                className="mb-3"
                                                style={{ color: '#000' }}
                                              >
                                                These insights provide a
                                                comprehensive overview of the
                                                trading data, revealing the
                                                frequency of trading, the
                                                prevalence of positive days, the
                                                average profitability on such
                                                days, and the impact of less
                                                frequent negative days on the
                                                trading strategy.
                                              </p>
                                              <div className="row">
                                                <div className="col-lg-7">
                                                  <div className="table-responsive">
                                                    <table className="table">
                                                      <tbody>
                                                        <tr>
                                                          <td className="bold-text">
                                                            Number of Days
                                                          </td>
                                                          <td>
                                                            {data?.Report
                                                              ?.trading_days_analysis
                                                              ?.calculation
                                                              ?.numDays ??
                                                              'N/A'}
                                                          </td>
                                                        </tr>
                                                        <tr>
                                                          <td className="bold-text">
                                                            Avg. no. of trades
                                                          </td>
                                                          <td>
                                                            {data?.Report
                                                              ?.trading_days_analysis
                                                              ?.calculation
                                                              ?.avgNoOfTradesPerDay ??
                                                              'N/A'}
                                                          </td>
                                                        </tr>
                                                        <tr>
                                                          <td className="bold-text">
                                                            Positive Days
                                                          </td>
                                                          <td>
                                                            {data?.Report
                                                              ?.trading_days_analysis
                                                              ?.calculation
                                                              ?.numPositiveDays ??
                                                              'N/A'}
                                                          </td>
                                                        </tr>
                                                        <tr>
                                                          <td className="bold-text">
                                                            Avg. Positive Day
                                                          </td>
                                                          <td>
                                                            {data?.Report
                                                              ?.trading_days_analysis
                                                              ?.calculation
                                                              ?.avgPositiveDay ??
                                                              'N/A'}
                                                          </td>
                                                        </tr>
                                                        <tr>
                                                          <td className="bold-text">
                                                            Negative Days
                                                          </td>
                                                          <td>
                                                            {data?.Report
                                                              ?.trading_days_analysis
                                                              ?.calculation
                                                              ?.numNegativeDays ??
                                                              'N/A'}
                                                          </td>
                                                        </tr>
                                                        <tr>
                                                          <td className="bold-text">
                                                            Avg. Negative Days
                                                          </td>
                                                          <td>
                                                            {data?.Report
                                                              ?.trading_days_analysis
                                                              ?.calculation !==
                                                            undefined
                                                              ? data.Report
                                                                  .trading_days_analysis
                                                                  .calculation
                                                                  ?.avgNegativeDay
                                                              : 'N/A'}
                                                          </td>
                                                        </tr>
                                                      </tbody>
                                                    </table>
                                                  </div>
                                                  <ul
                                                    className="p-0 m-0"
                                                    style={{
                                                      listStyle: 'none'
                                                    }}
                                                  >
                                                    <li className="mb-2">
                                                      <b>Number of Days: </b>
                                                      {data?.Report
                                                        ?.trading_days_analysis
                                                        ?.description[
                                                        'Number of Days'
                                                      ] ?? 'N/A'}
                                                    </li>
                                                    <li className="mb-2">
                                                      <b>
                                                        Avg. no. of trades:{' '}
                                                      </b>
                                                      {data?.Report
                                                        ?.trading_days_analysis
                                                        ?.description[
                                                        'Avg. no. of trades'
                                                      ] ?? 'N/A'}
                                                    </li>
                                                    <li className="mb-2">
                                                      <b>Positive Days: </b>
                                                      {data?.Report
                                                        ?.trading_days_analysis
                                                        ?.description[
                                                        'Positive Days'
                                                      ] ?? 'N/A'}
                                                    </li>
                                                    <li className="mb-2">
                                                      <b>Avg. Positive Day: </b>
                                                      {data?.Report
                                                        ?.trading_days_analysis
                                                        ?.description[
                                                        'Avg. Positive Day'
                                                      ] ?? 'N/A'}
                                                    </li>
                                                    <li className="mb-2">
                                                      <b>Negative Days: </b>
                                                      {data?.Report
                                                        ?.trading_days_analysis
                                                        ?.description[
                                                        'Negative Days'
                                                      ] ?? 'N/A'}
                                                    </li>
                                                    <li className="mb-2">
                                                      <b>Avg. Negative Day: </b>
                                                      {data?.Report
                                                        ?.trading_days_analysis
                                                        ?.description[
                                                        'Avg. Negative Day'
                                                      ] ?? 'N/A'}
                                                    </li>
                                                  </ul>
                                                </div>
                                                <div className="col-lg-5">
                                                  <div className="suggestions">
                                                    <h6 className="dark-green">
                                                      Suggestions
                                                    </h6>
                                                    {data?.Report
                                                      ?.trading_days_analysis
                                                      ?.suggestion
                                                      ? data.Report.trading_days_analysis.suggestion
                                                          .split('\n\n')
                                                          .map(
                                                            (
                                                              paragraph,
                                                              index
                                                            ) => (
                                                              <p key={index}>
                                                                {paragraph}
                                                              </p>
                                                            )
                                                          )
                                                      : 'N/A'}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="accordion-item">
                                          <h2
                                            className="accordion-header"
                                            id="headingFive"
                                          >
                                            <button
                                              className="accordion-button"
                                              type="button"
                                              data-bs-toggle="collapse"
                                              data-bs-target="#collapseFive"
                                              aria-expanded="true"
                                              aria-controls="collapseFive"
                                            >
                                              Results by Position Size
                                            </button>
                                          </h2>
                                          <div
                                            id="collapseFive"
                                            className="accordion-collapse collapse show"
                                            aria-labelledby="headingFive"
                                            data-bs-parent="#accordionExample"
                                          >
                                            <div class="accordion-body">
                                              <p
                                                className="mb-3"
                                                style={{ color: '#000' }}
                                              >
                                                These insights provide a clear
                                                picture of how different
                                                position sizes can influence
                                                trading results, emphasizing the
                                                importance of selecting an
                                                appropriate position size to
                                                achieve the desired risk-reward
                                                balance.
                                              </p>
                                              <div className="row">
                                                <div className="col-lg-7">
                                                  <div className="table-responsive">
                                                    <table className="table">
                                                      <thead>
                                                        <tr>
                                                          <th className="bold-text">
                                                            Position Size
                                                          </th>
                                                          <th className="bold-text">
                                                            # of Trades
                                                          </th>
                                                          <th className="bold-text">
                                                            Results
                                                          </th>
                                                        </tr>
                                                      </thead>
                                                      <tbody>
                                                        {Object.entries(
                                                          data?.Report
                                                            ?.results_by_position_size
                                                            ?.calculation ?? {}
                                                        ).map(
                                                          ([
                                                            positionSize,
                                                            positionData
                                                          ]) => (
                                                            <tr
                                                              key={positionSize}
                                                            >
                                                              <td className="bold-text">
                                                                {positionSize}
                                                              </td>
                                                              <td>
                                                                {positionData?.[
                                                                  'Num. of trades'
                                                                ] ?? 'N/A'}
                                                              </td>
                                                              <td>
                                                                {positionData?.Result ??
                                                                  'N/A'}
                                                              </td>
                                                            </tr>
                                                          )
                                                        )}
                                                      </tbody>
                                                    </table>
                                                  </div>
                                                  <ul
                                                    className="p-0 m-0"
                                                    style={{
                                                      listStyle: 'none'
                                                    }}
                                                  >
                                                    {Object.entries(
                                                      data?.Report
                                                        ?.results_by_position_size
                                                        ?.description ?? {}
                                                    ).map(([trade, value]) => (
                                                      <li
                                                        key={trade}
                                                        className="mb-2"
                                                      >
                                                        <b>{trade}:</b>{' '}
                                                        {value ?? 'N/A'}
                                                      </li>
                                                    ))}
                                                  </ul>
                                                </div>
                                                <div className="col-lg-5">
                                                  <div className="suggestions">
                                                    <h6 className="dark-green">
                                                      Suggestions
                                                    </h6>
                                                    {data?.Report
                                                      ?.results_by_position_size
                                                      ?.suggestion
                                                      ? data.Report.results_by_position_size.suggestion
                                                          .split('\n\n')
                                                          .map(
                                                            (
                                                              paragraph,
                                                              index
                                                            ) => (
                                                              <p key={index}>
                                                                {paragraph}
                                                              </p>
                                                            )
                                                          )
                                                      : 'N/A'}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="accordion-item">
                                          <h2
                                            className="accordion-header"
                                            id="headingSix"
                                          >
                                            <button
                                              className="accordion-button"
                                              type="button"
                                              data-bs-toggle="collapse"
                                              data-bs-target="#collapseSix"
                                              aria-expanded="true"
                                              aria-controls="collapseSix"
                                            >
                                              Results by Trade Duration
                                            </button>
                                          </h2>
                                          <div
                                            id="collapseSix"
                                            className="accordion-collapse collapse show"
                                            aria-labelledby="headingSix"
                                            data-bs-parent="#accordionExample"
                                          >
                                            <div class="accordion-body">
                                              <p
                                                className="mb-3"
                                                style={{ color: '#000' }}
                                              >
                                                These insights highlight the
                                                importance of choosing the right
                                                trade duration based on trading
                                                strategy and risk tolerance, as
                                                different durations may yield
                                                varying results. Traders may
                                                prefer high- frequency trading
                                                for quick gains or opt for
                                                longer durations for potentially
                                                more stable but less frequent
                                                profits.
                                              </p>
                                              <div className="row">
                                                <div className="col-lg-7">
                                                  <div className="table-responsive analysis-scrollar">
                                                    <table className="table">
                                                      <thead>
                                                        <tr>
                                                          <th className="bold-text">
                                                            Duration
                                                          </th>
                                                          <th className="bold-text">
                                                            # of Trades
                                                          </th>
                                                          <th className="bold-text">
                                                            Results
                                                          </th>
                                                        </tr>
                                                      </thead>
                                                      <tbody>
                                                        {data?.Report?.results_by_trade_duration?.calculation?.map(
                                                          (trade, index) => (
                                                            <tr key={index}>
                                                              <td className="bold-text">
                                                                {trade?.Duration ??
                                                                  'N/A'}
                                                              </td>
                                                              <td>
                                                                {trade?.[
                                                                  'Num. of trades'
                                                                ] ?? 'N/A'}
                                                              </td>
                                                              <td>
                                                                {trade?.Result ??
                                                                  'N/A'}
                                                              </td>
                                                            </tr>
                                                          )
                                                        )}
                                                      </tbody>
                                                    </table>
                                                  </div>
                                                  <ul
                                                    className="p-0 m-0"
                                                    style={{
                                                      listStyle: 'none'
                                                    }}
                                                  >
                                                    <li className="mb-2">
                                                      <b>Duration:</b>{' '}
                                                      {data?.Report
                                                        ?.results_by_trade_duration
                                                        ?.description
                                                        ?.Duration ?? 'N/A'}
                                                    </li>
                                                    <li className="mb-2">
                                                      <b># of Trades:</b>{' '}
                                                      {data?.Report
                                                        ?.results_by_trade_duration
                                                        ?.description[
                                                        '# of Trades'
                                                      ] ?? 'N/A'}
                                                    </li>
                                                    <li className="mb-2">
                                                      <b>Results:</b>{' '}
                                                      {data?.Report
                                                        ?.results_by_trade_duration
                                                        ?.description
                                                        ?.Results ?? 'N/A'}
                                                    </li>
                                                    <li className="mb-2">
                                                      <b>
                                                        High-Frequency Trading:
                                                      </b>{' '}
                                                      {data?.Report
                                                        ?.results_by_trade_duration
                                                        ?.description[
                                                        'High-Frequency Trading'
                                                      ] ?? 'N/A'}
                                                    </li>
                                                    <li className="mb-2">
                                                      <b>
                                                        Profitable Durations:
                                                      </b>{' '}
                                                      {data?.Report
                                                        ?.results_by_trade_duration
                                                        ?.description[
                                                        'Profitable Durations'
                                                      ] ?? 'N/A'}
                                                    </li>
                                                    <li className="mb-2">
                                                      <b>
                                                        Losses in Longer
                                                        Durations:
                                                      </b>{' '}
                                                      {data?.Report
                                                        ?.results_by_trade_duration
                                                        ?.description[
                                                        'Losses in Longer Durations'
                                                      ] ?? 'N/A'}
                                                    </li>
                                                  </ul>
                                                </div>
                                                <div className="col-lg-5">
                                                  <div className="suggestions">
                                                    <h6 className="dark-green">
                                                      Suggestions
                                                    </h6>
                                                    {data?.Report
                                                      ?.results_by_trade_duration
                                                      ?.suggestion
                                                      ? data.Report.results_by_trade_duration.suggestion
                                                          .split('\n\n')
                                                          .map(
                                                            (
                                                              paragraph,
                                                              index
                                                            ) => (
                                                              <p key={index}>
                                                                {paragraph}
                                                              </p>
                                                            )
                                                          )
                                                      : 'N/A'}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="accordion-item">
                                          <h2
                                            className="accordion-header"
                                            id="headingSeven"
                                          >
                                            <button
                                              className="accordion-button"
                                              type="button"
                                              data-bs-toggle="collapse"
                                              data-bs-target="#collapseSeven"
                                              aria-expanded="true"
                                              aria-controls="collapseSeven"
                                            >
                                              Results by Open Hour
                                            </button>
                                          </h2>
                                          <div
                                            id="collapseSeven"
                                            className="accordion-collapse collapse show"
                                            aria-labelledby="headingSeven"
                                            data-bs-parent="#accordionExample"
                                          >
                                            <div class="accordion-body">
                                              <p
                                                className="mb-3"
                                                style={{ color: '#000' }}
                                              >
                                                These insights underscore the
                                                importance of selecting the
                                                right trading hours to align
                                                with one's strategy and risk
                                                tolerance. The data highlights
                                                that certain hours are more
                                                conducive to profitable trading,
                                                while others may carry higher
                                                risk. Traders may use this
                                                information to optimize their
                                                trading schedules and
                                                strategies.
                                              </p>
                                              <div className="row">
                                                <div className="col-lg-7">
                                                  <div className="table-responsive">
                                                    <table className="table">
                                                      <thead>
                                                        <tr>
                                                          <th className="bold-text">
                                                            Hour
                                                          </th>
                                                          <th className="bold-text">
                                                            # of Trades
                                                          </th>
                                                          <th className="bold-text">
                                                            Results
                                                          </th>
                                                        </tr>
                                                      </thead>
                                                      <tbody>
                                                        {data?.Report?.results_by_open_hour?.calculation?.map(
                                                          (hour, index) => (
                                                            <tr key={index}>
                                                              <td className="bold-text">
                                                                {hour?.Hour ??
                                                                  'N/A'}
                                                              </td>
                                                              <td>
                                                                {hour?.[
                                                                  'Num. of trades'
                                                                ] ?? 'N/A'}
                                                              </td>
                                                              <td>
                                                                {hour?.Result ??
                                                                  'N/A'}
                                                              </td>
                                                            </tr>
                                                          )
                                                        )}
                                                      </tbody>
                                                    </table>
                                                  </div>
                                                  <ul
                                                    className="p-0 m-0"
                                                    style={{
                                                      listStyle: 'none'
                                                    }}
                                                  >
                                                    {Object.entries(
                                                      data?.Report
                                                        ?.results_by_open_hour
                                                        ?.description ?? {}
                                                    ).map(([key, value]) => (
                                                      <li
                                                        key={key}
                                                        className="mb-2"
                                                      >
                                                        <b>{key}:</b>{' '}
                                                        {value ?? 'N/A'}
                                                      </li>
                                                    ))}
                                                  </ul>
                                                </div>
                                                <div className="col-lg-5">
                                                  <div className="suggestions">
                                                    <h6 className="dark-green">
                                                      Suggestions
                                                    </h6>
                                                    {data?.Report
                                                      ?.results_by_open_hour
                                                      ?.suggestion
                                                      ? data.Report.results_by_open_hour.suggestion
                                                          .split('\n\n')
                                                          .map(
                                                            (
                                                              paragraph,
                                                              index
                                                            ) => (
                                                              <p key={index}>
                                                                {paragraph}
                                                              </p>
                                                            )
                                                          )
                                                      : 'N/A'}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className="tab-pane fade"
                                    id="nav-profile"
                                    role="tabpanel"
                                    aria-labelledby="nav-profile-tab"
                                  >
                                    <p className="trading-plan-heading">
                                      This trading plan corresponds to the
                                      analysis report generated on{' '}
                                      <span className="green">
                                        {(() => {
                                          const months = [
                                            'January',
                                            'February',
                                            'March',
                                            'April',
                                            'May',
                                            'June',
                                            'July',
                                            'August',
                                            'September',
                                            'October',
                                            'November',
                                            'December'
                                          ];
                                          const date = new Date(
                                            data?.Report?.timeFrame?.report_completed
                                          );
                                          const monthName =
                                            months[date.getMonth()];
                                          const day = date.getDate();
                                          const year = date.getFullYear() % 100;
                                          return `${day} ${monthName} ${year}`;
                                        })()}
                                      </span>
                                    </p>
                                    {tradingPlan && (
                                      <div className="trading-plan-block">
                                        <div className="trading-plans">
                                          <div className="profit-target">
                                            <label>Daily Profit Target</label>
                                            <div className="profit-progress">
                                              <button
                                                className="button-left-right"
                                                onClick={() =>
                                                  handleDecrease(
                                                    'daily_profit_target'
                                                  )
                                                }
                                              >
                                                <img
                                                  alt="leftArrow"
                                                  src={leftArrow}
                                                />
                                              </button>
                                              <div className="profit_rate">
                                                {updateTradingPlan?.daily_profit_target <
                                                0 ? (
                                                  <div className="profit_rate">
                                                    - $
                                                    {Math.abs(
                                                      updateTradingPlan?.daily_profit_target ??
                                                        0
                                                    ).toLocaleString()}
                                                  </div>
                                                ) : (
                                                  <div className="profit_rate">
                                                    $
                                                    {Math.abs(
                                                      updateTradingPlan?.daily_profit_target ??
                                                        0
                                                    ).toLocaleString()}
                                                  </div>
                                                )}
                                              </div>

                                              <button
                                                className="button-left-right"
                                                onClick={() =>
                                                  handleIncrease(
                                                    'daily_profit_target'
                                                  )
                                                }
                                              >
                                                <img
                                                  alt="rightArrow"
                                                  src={rightArrow}
                                                />
                                              </button>
                                            </div>
                                          </div>
                                          <div className="profit-target">
                                            <label>
                                              Profit Target per Trade
                                            </label>
                                            <div className="profit-progress">
                                              <button
                                                className="button-left-right"
                                                onClick={() =>
                                                  handleDecrease(
                                                    'profit_target_per_trade'
                                                  )
                                                }
                                              >
                                                <img
                                                  alt="leftArrow"
                                                  src={leftArrow}
                                                />
                                              </button>
                                              <div className="profit_rate">
                                                {updateTradingPlan?.profit_target_per_trade <
                                                0 ? (
                                                  <div className="profit_rate">
                                                    - $
                                                    {Math.abs(
                                                      updateTradingPlan?.profit_target_per_trade ??
                                                        0
                                                    ).toLocaleString()}
                                                  </div>
                                                ) : (
                                                  <div className="profit_rate">
                                                    $
                                                    {Math.abs(
                                                      updateTradingPlan?.profit_target_per_trade ??
                                                        0
                                                    ).toLocaleString()}
                                                  </div>
                                                )}
                                              </div>
                                              <button
                                                className="button-left-right"
                                                onClick={() =>
                                                  handleIncrease(
                                                    'profit_target_per_trade'
                                                  )
                                                }
                                              >
                                                <img
                                                  alt="rightArrow"
                                                  src={rightArrow}
                                                />
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="trading-plans">
                                          <div className="profit-target">
                                            <label>Maximum Daily Loss</label>
                                            <div className="profit-progress">
                                              <button
                                                className="button-left-right"
                                                onClick={() =>
                                                  handleDecrease(
                                                    'maximum_daily_loss'
                                                  )
                                                }
                                              >
                                                <img
                                                  alt="leftArrow"
                                                  src={leftArrow}
                                                />
                                              </button>
                                              {updateTradingPlan?.maximum_daily_loss <
                                              0 ? (
                                                <div className="profit_rate">
                                                  - $
                                                  {Math.abs(
                                                    updateTradingPlan?.maximum_daily_loss ??
                                                      0
                                                  ).toLocaleString()}
                                                </div>
                                              ) : (
                                                <div className="profit_rate">
                                                  $
                                                  {Math.abs(
                                                    updateTradingPlan?.maximum_daily_loss ??
                                                      0
                                                  ).toLocaleString()}
                                                </div>
                                              )}

                                              <button
                                                className="button-left-right"
                                                onClick={() =>
                                                  handleIncrease(
                                                    'maximum_daily_loss'
                                                  )
                                                }
                                              >
                                                {' '}
                                                <img
                                                  alt="rightArrow"
                                                  src={rightArrow}
                                                />
                                              </button>
                                            </div>
                                          </div>
                                          <div className="profit-target">
                                            <label>Stop Loss</label>
                                            <div className="profit-progress">
                                              <button
                                                className="button-left-right"
                                                onClick={() =>
                                                  handleDecrease('stop_loss')
                                                }
                                              >
                                                <img
                                                  alt="leftArrow"
                                                  src={leftArrow}
                                                />
                                              </button>
                                              {updateTradingPlan?.stop_loss <
                                              0 ? (
                                                <div className="profit_rate">
                                                  - $
                                                  {Math.abs(
                                                    updateTradingPlan?.stop_loss
                                                  ).toLocaleString()}
                                                </div>
                                              ) : (
                                                <div className="profit_rate">
                                                  $
                                                  {updateTradingPlan?.stop_loss.toLocaleString()}
                                                </div>
                                              )}
                                              <button
                                                className="button-left-right"
                                                onClick={() =>
                                                  handleIncrease('stop_loss')
                                                }
                                              >
                                                {' '}
                                                <img
                                                  alt="rightArrow"
                                                  src={rightArrow}
                                                />
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="trading-plans">
                                          <div className="profit-target">
                                            <label>
                                              Maximum Trades per Day
                                            </label>
                                            <div className="profit-progress">
                                              <button
                                                className="button-left-right"
                                                onClick={() =>
                                                  handleDecrease(
                                                    'maximum_trades_per_day'
                                                  )
                                                }
                                              >
                                                <img
                                                  alt="leftArrow"
                                                  src={leftArrow}
                                                />
                                              </button>
                                              <div className="profit_rate">
                                                {updateTradingPlan?.maximum_trades_per_day <
                                                0 ? (
                                                  <div className="profit_rate">
                                                    -
                                                    {Math.abs(
                                                      updateTradingPlan?.maximum_trades_per_day
                                                    )}
                                                  </div>
                                                ) : (
                                                  <div className="profit_rate">
                                                    {
                                                      updateTradingPlan?.maximum_trades_per_day
                                                    }
                                                  </div>
                                                )}
                                              </div>
                                              <button
                                                className="button-left-right"
                                                onClick={() =>
                                                  handleIncrease(
                                                    'maximum_trades_per_day'
                                                  )
                                                }
                                              >
                                                {' '}
                                                <img
                                                  alt="rightArrow"
                                                  src={rightArrow}
                                                />
                                              </button>
                                            </div>
                                          </div>
                                          <div className="profit-target">
                                            <label>Maximum position Size</label>
                                            {/* <div className="profit-progress">
                                              <button
                                                className="button-left-right"
                                                onClick={() =>
                                                  handleDecrease(
                                                    'maximum_position_size'
                                                  )
                                                }
                                              >
                                                <img
                                                  alt="leftArrow"
                                                  src={leftArrow}
                                                />
                                              </button>
                                              <div className="profit_rate">
                                                {
                                                  updateTradingPlan?.maximum_position_size
                                                }
                                              </div>
                                              <button
                                                className="button-left-right"
                                                onClick={() =>
                                                  handleIncrease(
                                                    'maximum_position_size'
                                                  )
                                                }
                                              >
                                                <img
                                                  alt="rightArrow"
                                                  src={rightArrow}
                                                />
                                              </button>
                                            </div> */}
                                            <div className="profit-progress">
                                              <button
                                                className="button-left-right"
                                                onClick={() =>
                                                  handleDecrease(
                                                    'maximum_position_size'
                                                  )
                                                }
                                              >
                                                <img
                                                  alt="leftArrow"
                                                  src={leftArrow}
                                                />
                                              </button>
                                              <input
                                                // type="text"
                                                className="profit_rate"
                                                value={
                                                  updateTradingPlan?.maximum_position_size
                                                }
                                                onChange={(e) =>
                                                  handleInputChange(
                                                    'maximum_position_size',
                                                    e.target.value
                                                  )
                                                }
                                              />
                                              <button
                                                className="button-left-right"
                                                onClick={() =>
                                                  handleIncrease(
                                                    'maximum_position_size'
                                                  )
                                                }
                                              >
                                                <img
                                                  alt="rightArrow"
                                                  src={rightArrow}
                                                />
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="trading-plans">
                                          <div className="profit-target">
                                            <label className="mb-2">
                                              Avoid Day of the week
                                            </label>
                                            <select
                                              className="form-select"
                                              onChange={handleAvoidDayChange}
                                            >
                                              <option>
                                                {
                                                  updateTradingPlan?.avoid_day_of_week
                                                }
                                              </option>
                                              <option>Monday</option>
                                              <option>Tuesday</option>
                                              <option>Wednesday</option>
                                              <option>Thursday</option>
                                              <option>Friday</option>
                                              <option>Saturday</option>
                                              <option>Sunday</option>
                                            </select>
                                            {/* <div id="progress">
                                        <div class="progressBar"></div>
                                        <div class="progressNow" value="1">
                                          <span>$0.00</span>
                                        </div>
                                        <div class="progressTotal"></div>
                                      </div> */}
                                          </div>
                                          <div className="profit-target">
                                            <label className="mb-2">
                                              Avoid Time of Day
                                            </label>
                                            <select
                                              className="form-select"
                                              onChange={handleAvoidTimeChange}
                                            >
                                              <option>
                                                {
                                                  updateTradingPlan?.avoid_time_of_day
                                                }
                                              </option>
                                              {[...Array(24)].map(
                                                (_, index) => (
                                                  <option
                                                    key={index}
                                                    value={`${index
                                                      .toString()
                                                      .padStart(
                                                        2,
                                                        '0'
                                                      )}:00-${index
                                                      .toString()
                                                      .padStart(2, '0')}:59`}
                                                  >
                                                    {`${index
                                                      .toString()
                                                      .padStart(
                                                        2,
                                                        '0'
                                                      )}:00-${index
                                                      .toString()
                                                      .padStart(2, '0')}:59`}
                                                  </option>
                                                )
                                              )}
                                            </select>
                                          </div>
                                        </div>
                                      </div>
                                    )}

                                    <p className="trading-footer-text">
                                      While you have the option to adjust the
                                      recommended values, doing so may render
                                      the trading analysis invalid.
                                    </p>
                                    <div className="text-center">
                                      <button
                                        onClick={handleUpdateTradingPlan}
                                        className="btn btn-primary btn-sm"
                                        disabled={!updateTradingPlanButton}
                                      >
                                        {updateDataLoading
                                          ? 'Please wait.....'
                                          : ' I understand, please update.'}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                      <ChatbotMobile />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="right_container">
              <ChatbotWeb
                from={'analysis'}
                selectedLogin={selectedLogin}
                activeAccounts={accounts}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Analysis;
