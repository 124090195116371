/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { message } from 'antd';

import brandLogo from '../../assets/images/logo.png';
import './styles.scss';
import axios from 'axios';
import moment from 'moment-timezone';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import AppConfig from '../../config';
import PasswordField from './passwordFeld';
import TimeZoneSelect from './timeZone';

const CreateAccount = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [selectedTimezone, setSelectedTimezone] = useState('');
  const tokenValue = localStorage.getItem('jwtToken');

  const [userData, setUserData] = useState({
    timezone: ''
  });

  useEffect(() => {
    if (tokenValue) {
      navigate('/accounts');
    }
  }, [tokenValue]);

  const handleChange = (selectedOption) => {
    setSelectedTimezone(selectedOption.value);
    setUserData({ ...userData, timezone: selectedOption.value });
  };

  const getTimeZoneWithOffset = (tz) => {
    const offset = moment.tz(tz).format('Z');
    return `(GMT${offset}) ${tz}`;
  };

  async function createUserAccount(values) {
    try {
      setLoading(true);
      setErrorMessage('');
      const userData = {
        first_name: values.firstName,
        last_name: values.lastName,
        email: values.email,
        password: values.password,
        timezone: values.timezone,
        confirm_password: values.confirmPassword,
        is_Agreed_To_Terms: values.isAgreedToTerms,
        is_news_letter_subscribed: values.isNewsletterSubscribed,
        twoFA: true
      };

      const response = await axios.post(
        AppConfig.apiUrl + '/create_temporary_user',
        userData,
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      if (response?.data?.success) {
        localStorage.setItem('twoFa', true);
        navigate('/otp', {
          state: { newUserData: response?.data, isNewUser: true }
        });
        setLoading(false);
      }

      // const token = response.data.data.token;
      // localStorage.setItem('jwtToken', token);
      // dispatch(setUserdetails(response?.data?.data));
      // dispatch(setUserId(response?.data?.data?.user?._id));

      // if (response && response.data.success) {
      //   const data = await getUserDetails();
      //   dispatch(updateAccounts(data?.accounts || []));
      //   if (data.success) {
      //     setErrorMessage('');
      //     navigate(
      //       `/new-account?account_id=${response?.data?.data.user.account_ids[0]}`
      //     );
      //   }
      // } else {
      //   setErrorMessage(response.data.message);
      // }
    } catch (error) {
      setLoading(false);
      console.error('An error occurred:', error);
      // setErrorMessage(
      //   error?.response?.data?.message
      // );
      message.error(error?.response?.data?.message,3);
    }
  }

  const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    timezone: '',
    password: '',
    confirmPassword: '',
    isAgreedToTerms: false,
    isNewsletterSubscribed: false
  };

  const validationSchema = Yup.object({
    firstName: Yup.string()
      .matches(
        /^[a-zA-Z]+(?:\s[a-zA-Z]+)*$/,
        'First Name must contain only letters'
      )
      .required('First Name is required'),
    lastName: Yup.string()
      .matches(
        /^[a-zA-Z]+(?:\s[a-zA-Z]+)*$/,
        'Last Name must contain only letters'
      )
      .required('Last Name is required'),

    email: Yup.string()
      .test('valid-email', 'Invalid email address', function (value) {
        if (value && value.trim()) {
          return Yup.string().email().isValidSync(value);
        }
        return true;
      })
      .required('Email is required'),

    timezone: Yup.string().required('Timezone is required'),

    password: Yup.string()
      .max(20, 'Password should not be 20 characters or more.')
      .required('Password is required')
      .test(
        'password',
        'Password validation requires a minimum of 8 characters, including a combination of uppercase and lowercase letters, special characters, and numbers. ',
        (value) => {
          return (
            /[A-Z]/.test(value) &&
            /\d/.test(value) &&
            /[@$!%*?&]/.test(value) &&
            value.length >= 8 &&
            !/\s/.test(value)
          );
        }
      ),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required('Confirm Password is required'),
    isAgreedToTerms: Yup.boolean().oneOf(
      [true],
      'You must agree to the Terms & Conditions and Privacy Policy'
    )
  });

  return (
    <>
      <div className="login-wrapper">
        <div className="login-container">
          <div className="loginBlock">
            <div className="brandLogo">
              <img src={brandLogo} alt="Brand Logo" />
            </div>
            <Formik
              initialValues={initialValues}
              onSubmit={createUserAccount}
              validationSchema={validationSchema}
              validateOnChange={true}
              validateOnBlur={true}
            >
              {({ values, handleChange, handleBlur, setFieldValue }) => (
                <Form className="login-form">
                  <div className="sign-title">Create your account</div>
                  <div className="row ">
                    <div className="col-lg-6 pb-2">
                      <Field
                        type="text"
                        name="firstName"
                        placeholder="First Name"
                        className="form-control"
                      />
                      <ErrorMessage
                        component="span"
                        name="firstName"
                        className="invalid-feedback d-block"
                      />
                    </div>

                    <div className="col-lg-6 pb-2">
                      <Field
                        type="text"
                        name="lastName"
                        placeholder="Last Name"
                        className="form-control"
                      />
                      <ErrorMessage
                        component="span"
                        name="lastName"
                        className="invalid-feedback d-block"
                      />
                    </div>
                  </div>
                  <div className="pb-2">
                    <Field
                      type="email"
                      name="email"
                      placeholder="Email"
                      className="form-control"
                    />

                    <ErrorMessage
                      component="span"
                      name="email"
                      className="invalid-feedback d-block"
                    />
                  </div>
                  <div className="pb-2">
                    <TimeZoneSelect
                      name="timezone"
                      className="timeZone"
                      placeholder="Timezone"
                    />

                    <ErrorMessage
                      component="span"
                      name="timezone"
                      className="invalid-feedback d-block"
                    />
                  </div>
                  <div className="pb-2">
                    <Field
                      type="password"
                      name="password"
                      component={PasswordField}
                      placeholder="Password"
                      className="form-control"
                      onChange={(e) => {
                        const password = e.target.value;
                        const Value = password.slice(0, 21);
                        setFieldValue('password', Value);
                      }}
                    />
                    <ErrorMessage
                      component="span"
                      name="password"
                      className="invalid-feedback d-block"
                    />
                    {passwordError && (
                      <div
                        className="error-message"
                        style={{ color: 'red', textAlign: 'center' }}
                      >
                        {passwordError}
                      </div>
                    )}
                  </div>
                  <div className="pb-2">
                    <Field
                      type="password"
                      name="confirmPassword"
                      component={PasswordField}
                      placeholder="Confirm Password"
                      className="form-control"
                      onPaste={(e) => e.preventDefault()}
                    />
                    <ErrorMessage
                      component="span"
                      name="confirmPassword"
                      className="invalid-feedback d-block"
                    />
                  </div>
                  <div className="pb-2">
                    <div className="my-checkbox  me-2">
                      <Field
                        type="checkbox"
                        name="isAgreedToTerms"
                        className="my-checkbox__input"
                      />
                    </div>
                    I agree to the{' '}
                    <a
                      onClick={() => {
                        navigate('/terms-of-service');
                      }}
                      className="link_color cursor-pointer"
                    >
                      Terms & Conditions
                    </a>{' '}
                    and{' '}
                    <a
                      onClick={() => {
                        navigate('/privacy-policy');
                      }}
                      className="link_color cursor-pointer"
                    >
                      Privacy Policy
                    </a>
                    <ErrorMessage
                      component="span"
                      name="isAgreedToTerms"
                      className="invalid-feedback d-block"
                    />
                  </div>
                  <div className="pb-2">
                    <div className="my-checkbox  me-2">
                      <Field
                        type="checkbox"
                        name="isNewsletterSubscribed"
                        className="my-checkbox__input"
                      />
                    </div>
                    Subscribe to the Newsletter
                  </div>
                  <div>
                    {errorMessage && (
                      <div
                        className="error-message"
                        style={{ color: 'red', textAlign: 'center' }}
                      >

                      </div>
                    )}
                  </div>
                  <div className="pb-2">
                    <button
                      disabled={loading}
                      type="submit"
                      className="btn btn-sm btn-primary w-100"
                    >
                      {loading ? 'Please wait...' : 'Next'}
                    </button>
                  </div>
                  <div>
                    <p>
                      Already have an account?{' '}
                      <a
                        onClick={() => {
                          navigate('/');
                        }}
                        className="link_color cursor-pointer"
                        style={{ textDecoration: 'none' }}
                      >
                        Sign in
                      </a>
                    </p>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateAccount;
