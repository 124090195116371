import moment from 'moment-timezone';
import { useState, useEffect } from 'react';

const closingTime = '16:30:00';
const timeZone = moment.tz.guess();

export const calculateTimeRemaining = () => {
  const now = moment();
  const closing = moment.tz(
    `${now.format('YYYY-MM-DD')} ${closingTime}`,
    'YYYY-MM-DD HH:mm:ss',
    timeZone
  );
  if (!closing.isValid()) {
    console.error(
      `Invalid closing time or time zone: ${closingTime}, ${timeZone}`
    );
    return;
  }

  let duration;

  if (now.isBefore(closing)) {
    duration = moment.duration(closing.diff(now));
  } else {
    // If current time is after closing time, calculate the time remaining until the next closing time
    const nextClosing = closing.add(1, 'days');
    duration = moment.duration(nextClosing.diff(now));
  }

  const hours = duration.hours();
  const minutes = duration.minutes();

  const remainingTime = hours + ' hrs & ' + minutes + ' mins';

  return remainingTime;
};

export const getEarliestActiveAccount = (accounts) => {
  const earliestActiveAccount = accounts
    .filter((account) => account.status === 'active')
    .reduce(
      (earliest, current) => {
        const earliestDate = new Date(earliest.created_at);
        const currentDate = new Date(current.created_at);
        return earliestDate < currentDate ? earliest : current;
      },
      accounts.find((account) => account.status === 'active')
    );
  return earliestActiveAccount.account_id;
};

export const filterByAccountId = (accounts, accountId) => {
  const account = accounts.filter((item) => item.account_id === accountId);
  return account[0].status;
};

export const checkIfNewUser = (accounts) => {
  const activeCount = accounts?.filter(
    (account) => account?.status === 'active'
  ).length;
  const pendingCount = accounts?.filter(
    (account) => account?.status === 'pending'
  ).length;

  if (activeCount === 0 && pendingCount) {
    return true;
  } else {
    return false;
  }
};

export const isTokenExpired = (storedToken) => {
  if (storedToken) {
    function parseJwt(token) {
      try {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(
          atob(base64)
            .split('')
            .map(function (c) {
              return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join('')
        );

        return JSON.parse(jsonPayload);
      } catch (error) {
        console.error('Invalid token', error);
        return null;
      }
    }
    try {
      const decodedToken = parseJwt(storedToken);
      const storedExpiryTime = decodedToken.exp;
      // Check if expiry time is available
      if (storedExpiryTime) {
        // Convert expiry timestamp to milliseconds
        const expiryTimeMs = Number(storedExpiryTime) * 1000;
        // Get the current time in milliseconds
        const currentTimeMs = Date.now();
        // Calculate the difference in milliseconds
        const differenceMs = expiryTimeMs - currentTimeMs;
        console.log(`token will expired in ${differenceMs} time`);
        if (differenceMs <= 0) {
          console.log('User token has expired');
          localStorage.clear();
          sessionStorage.removeItem('persist:root');
          window.location.replace('/');
        }
      } else {
        console.log('Expiry time not found in token');
      }
    } catch (error) {
      console.error('Error decoding token:', error);
    }
  } else {
    console.log('Token not found in local storage');
  }
};

export const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(
    window.matchMedia('(max-width: 768px)').matches
  );

  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 768px)');
    const handleResize = () => {
      setIsMobile(mediaQuery.matches);
    };

    mediaQuery.addEventListener('change', handleResize);

    return () => mediaQuery.removeEventListener('change', handleResize);
  }, []);

  return isMobile;
};

export const capitalizeFirstLetter = (str) => {
  if (!str) return ''; // Handle empty string or null/undefined
  return str.charAt(0).toUpperCase() + str.slice(1);
};
