import { useSelector, useDispatch } from 'react-redux';
import './styles.scss';
import {
  addAccounts,
  selectUserName,
  getnewAccountButtonDisabled,
  setnewAccountButtonDisabled,
  setDefaultAccount
} from '../login/loginSlice';
import { calculateTimeRemaining } from '../../utils/helper';
import { clearChatMessage } from '../../components/chatbot/ChatSlice';
import { AddCricle } from 'assets/svgIcons';
import { exchangeName } from '../../utils/constants';
import { useNavigate, useLocation } from 'react-router-dom';
import { createNewAccount } from '../fa-account/accountApi';
import React, { useState, useEffect } from 'react';
import TimeIcon from '../../assets/Img/time-icon.png';
import ReloadIcon from '../../assets/Img/reload.gif';
import {
  setCurrentAccount,
  setCurrentLogin
} from '../../components/chatbot/ChatSlice';
import { message } from 'antd';

const SubHeaderComponent = ({
  accountId,
  accounts,
  handleSelectAccount,
  onRefresh,
  loader2
}) => {
  const UserData = useSelector(selectUserName);
  const newAccountButtonDisabled = useSelector(getnewAccountButtonDisabled);
  const location = useLocation();
  const navigate = useNavigate();
  const [accountStatus, setAccountStatus] = useState('');
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  const [selectedAccount, setSelectedAccount] = useState(accountId);

  useEffect(() => {
    if (accounts?.length > 0) {
      let currentAccount;
      if (accountId) {
        currentAccount = accounts?.find(
          (account) => account?.account_id === accountId
        );
      } else if (
        location.pathname.startsWith('/overview') &&
        accounts?.length > 0
      ) {
        currentAccount = accounts[0];
        setSelectedAccount(currentAccount.account_id);
        navigate(`/overview/${currentAccount.account_id}`, { replace: true });
      }
      if (currentAccount) {
        dispatch(setDefaultAccount(currentAccount.account_id));
        setAccountStatus(currentAccount.status);
        setSelectedAccount(currentAccount.account_id);
      }
    }
  }, [accounts, accountId, location.pathname, navigate]);

  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    const selected_account =
      accounts &&
      accounts.find((account) => account.account_id === selectedValue);
    handleSelectAccount(selectedValue);
    setSelectedAccount(selectedValue);
    dispatch(setCurrentAccount(selected_account.login));
    dispatch(setCurrentLogin(selectedValue));
    const selectedAccount = accounts?.find(
      (account) => account.account_id === selectedValue
    );
    setAccountStatus(selectedAccount?.status);
    dispatch(clearChatMessage(true));
    navigate(`/overview/${selectedValue}`, { replace: true });
  };

  const addNewAccount = async () => {
    try {
      setLoader(true);
      dispatch(setnewAccountButtonDisabled(true));
      const response = await createNewAccount();
      if (response?.success) {
        dispatch(addAccounts(response?.new_account));
        dispatch(setnewAccountButtonDisabled(false));
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      dispatch(setnewAccountButtonDisabled(false));
      message.error('Please try again');
    }
  };

  const refreshAccount = () => {
    onRefresh(selectedAccount);
  };

  const isOverviewPath = location.pathname.startsWith('/overview');

  return (
    <div className="page-title">
      <div className="page-title-wrapper">
        <div className="page-title-heading">
          {isOverviewPath ? (
            <div className="d-flex">
              <p className="FA-heading">
                <span
                  className={accountStatus === 'active' ? 'green' : 'yellow'}
                >
                  Account #
                </span>
              </p>
              <select onChange={handleSelectChange} className="custom-select">
                {accounts.map((account, index) => (
                  <option
                    key={index}
                    value={account.account_id}
                    selected={accountId === account.account_id}
                  >
                    {account.account_id}
                  </option>
                ))}
              </select>
            </div>
          ) : (
            <h3>Greetings {UserData}!</h3>
          )}
          {/* <p className="title-subheading"> */}
            {/* {exchangeName} closes in{' '} */}
            {/* <span style={{ color: '#fff' }}>{calculateTimeRemaining()}</span> */}
         
            {/* {isOverviewPath && <></>} */}
          {/* </p> */}
        </div>
        {!isOverviewPath && (
          <button
            className="btn btn-outline gap-1"
            onClick={addNewAccount}
            disabled={newAccountButtonDisabled}
          >
            <AddCricle /> {loader ? 'Please wait...' : 'New Account'}
          </button>
        )}
        {isOverviewPath && (
          <div className="page-title-actions">
            <div className="d-flex align-items-center  gap-2 justify-content-md-end justify-content-start">
              <div
                className={
                  accountStatus === 'active' ? 'green-dote' : 'yellow-dote'
                }
              ></div>
              <p>
                Account: {selectedAccount} {accountStatus}{' '}
              </p>
              <button className="time-icon" onClick={refreshAccount}>
                <img alt="TimeIcon" src={loader2 ? ReloadIcon : TimeIcon} />
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SubHeaderComponent;
