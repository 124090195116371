import React, { useState, useEffect, memo } from 'react';
import { Pagination } from 'antd';
import EditIcon from '../../assets/svgIcons/EditIcon';
import moment from 'moment';
import { getFormattedDateTime } from 'utils/date';
import { TradeSide } from 'models/enums/tradeSide';
import { useSelector, useDispatch } from 'react-redux';
import './styles.scss';
import FileIcon from '../../assets/svgIcons/FileIcon';

const addLineBreakInDateTime = true;

const TradeTable = ({ accountNo, appliedFilters, onRowPress }) => {
  const [page, setPage] = useState({ page: 1, limit: 10 });

  // Access the trades and totalCount from Redux store
  const trades = useSelector((state) => state.home.trades);
   const updatedTrades = [...trades];
  console.log(trades,"tradesTable");
  const totalCount = useSelector((state) => state.home.totalCount); // Access totalCount
  console.log(totalCount,);

  // useEffect(() => {
  //   setPage({ page: 1, limit: 10 });
  // }, [accountNo, appliedFilters]);

  const calculateDuration = (startTime, endTime) => {
    const startMoment = moment(startTime);
    const endMoment = moment(endTime);
    const duration = moment.duration(endMoment.diff(startMoment));

    const months = endMoment.diff(startMoment, 'months');
    const days = duration.days();
    const hours = duration.hours();
    const minutes = duration.minutes();

    const components = [
      { value: months, unit: 'M' },
      { value: days, unit: 'D' },
      { value: hours, unit: 'h' },
      { value: minutes, unit: 'm' }
    ];

    const filteredComponents = components.filter(
      (component) => component.value !== 0
    );

    const formattedDuration = filteredComponents
      .map((component) => `${component.value} ${component.unit}`)
      .join(', ');

    return formattedDuration === '' ? '--' : formattedDuration;
  };

  const handlePaginationChange = (newPage, newLimit) => {
    setPage({ page: newPage, limit: newLimit });
    // Optionally, fetch the new page data based on `newPage` and `newLimit` if needed
    // fetchTradeData(newPage, newLimit); 
  };

  // Use pagedTrades to correctly slice the trades array based on current page and limit
  const pagedTrades = trades.slice((page.page - 1) * page.limit, page.page * page.limit);
  console.log(pagedTrades,"pageTrades");

  return (
    <>
      <div className="table-responsive table-wrapper p-0">
        <table className="table m-0">
          <thead>
            <tr>
              <th>Open</th>
              <th>Symbol</th>
              <th>Close</th>
              <th>Entry Price</th>
              <th>Exit Price</th>
              <th>Type</th>
              <th>Volume</th>
              <th>Duration</th>
              <th>Net P & L</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {trades && trades.length === 0 ? (
              <tr>
                <td colSpan={10} style={{ textAlign: 'center' }}>
                
                </td>
              </tr>
            ) : (
              // Use pagedTrades to display the trades for the current page
              pagedTrades.map((trade) => (
                <tr key={trade._id}>
                  <td>{getFormattedDateTime(trade.tradeTime, addLineBreakInDateTime)}</td>
                  <td>{trade.symbol}</td>
                  <td>{getFormattedDateTime(trade.closingTime, addLineBreakInDateTime)}</td>
                  <td>{trade.openPrice}</td>
                  <td>{trade.currentPrice}</td>
                  <td>
                    <span className={trade.type === TradeSide.BUY ? 'buy' : 'sell'}>
                      {trade.type}
                    </span>
                  </td>
                  <td>{trade.volume}</td>
                  <td>{calculateDuration(trade.tradeTime, trade.closingTime)}</td>
                  <td className={trade?.currentProfit > 0 ? 'green' : 'red'}>
                    {trade?.currentProfit !== undefined &&
                      trade?.currentProfit !== null &&
                      !isNaN(parseFloat(trade.currentProfit))
                      ? `${trade.currentProfit < 0 ? '-$' : '$'}${Math.abs(trade.currentProfit).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
                      : 'N/A'}
                  </td>

                  <td>
                    {trade?.insights_details && Object.keys(trade.insights_details)?.length !== 0 ? (
                      <a>
                        <FileIcon
                          className="icon"
                          onClick={() => {
                            onRowPress(trade);
                          }}
                        />
                      </a>
                    ) : (
                      <a>
                        <EditIcon
                          className="icon"
                          onClick={() => {
                            onRowPress(trade);
                          }}
                        />
                      </a>
                    )}
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
        {trades?.length && !trades.isLoading ? (
          <div className="d-flex justify-content-between align-items-center p-3">
            <p className="m-0" style={{ whiteSpace: 'nowrap' }}>
              {(page.page - 1) * page.limit + 1} to{' '}
              {Math.min(page.page * page.limit)} of {totalCount} results
            </p>
            <Pagination
              total={totalCount}
              current={page.page}
              onChange={(newPage, pageSize) =>
                setPage({ page: newPage, limit: pageSize })
              }
              defaultPageSize={page.limit}
              defaultCurrent={1}
              className="table--pagination"
            />
          </div>
        ) : (
          <div className="blank__table__card">
            {!trades.isLoading && (
              <div className="blank__table__card__inner">No trades found</div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default memo(TradeTable);
