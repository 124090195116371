import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useSelector } from 'react-redux';
import { selectUserDetails } from '../features/login/loginSlice';
import { stripeCheckout } from '../features/three-steps/accountApi';

export default function SubscriptionModal({
  accountId,
  paymentModal,
  setPaymentModal
}) {
  const user = useSelector(selectUserDetails);
  const [selectedPlan, setSelectedPlan] = useState('monthly');

  const toggle = () => setPaymentModal(false);

  const handleRadioChange = (event) => {
    setSelectedPlan(event.target.value);
  };

  const _subscription = async () => {
    setPaymentModal(false);
    try {
      const plan = selectedPlan.toLowerCase();
      const customer_id = user?.user?.stripe_customer_id;
      await stripeCheckout(plan, accountId, customer_id);
    } catch (error) {
      console.log('error subscription:', error);
      throw error;
    }
  };

  return (
    <div>
      <Modal
        isOpen={paymentModal}
        toggle={toggle}
        centered
        className="new-account-modal"
      >
        <ModalHeader toggle={toggle}>Account # {accountId}</ModalHeader>
        <ModalBody>
          <h1>
            <span className="rate">
              {selectedPlan === 'monthly' ? '$49' : '$509'}
            </span>
            <span className="monthly">
              {selectedPlan === 'monthly' ? '.95 monthly' : '.49 yearly'}
            </span>
          </h1>
          <div className="monthly-yearly-toggle-block">
            <div class="pricing-switcher">
              <div className="fieldset">
                <input
                  type="radio"
                  name="group-a"
                  value="monthly"
                  id="monthly-1"
                  checked={selectedPlan === 'monthly'}
                  onChange={handleRadioChange}
                />
                <label htmlFor="monthly-1">Monthly</label>
                <input
                  type="radio"
                  name="group-a"
                  value="yearly"
                  id="yearly-1"
                  checked={selectedPlan === 'yearly'}
                  onChange={handleRadioChange}
                />
                <label htmlFor="yearly-1">Yearly</label>
                <span className="switch"></span>
              </div>
            </div>
            <span className="yellowText">Save 15%</span>
          </div>
          <ul className="plans-list">
            <li>Ai Trading Analysis</li>
            <li>Personalized Trading Plan</li>
            <li>Real-time AI Chat</li>
            <li>Live Consistency Score</li>
            <li>Multi-Account Management</li>
            <li>Dedicated Trade Analysis</li>
            <li>Journal and Insights</li>
            <li>Secure Data Handling</li>
          </ul>
        </ModalBody>
        <ModalFooter className="justify-content-center">
          <Button
            className="btn-sm w-25"
            color="primary"
            onClick={_subscription}
          >
            Next
          </Button>
          <span className="footer-title">
            Cancel your subscription at any time without any hassle.
          </span>
        </ModalFooter>
      </Modal>
    </div>
  );
}
