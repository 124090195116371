import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import AppConfig from '../../config';

interface ChatMessage {
  type: 'bot' | 'user';
  text: string;
  timestamp: string;
}

interface ChatbotState {
  chatMessages: ChatMessage[];
  selectedAccount: string;
  selectedLogin: string;
  clearChat: boolean;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
  currentAccount: string;
  currentLogin: string;
}

// Initial state with type annotations
const initialState: ChatbotState = {
  chatMessages: [
    {
      type: 'bot',
      text: `🎉 Welcome to Fractal Alpha! 🎉\n\nYou've successfully created your account, and I'm here to help elevate your trading game.\nAs your AI trading assistant, I'm available 24/7 to provide insights, answer questions, and guide you through your trading journey.\nLet's achieve your goals together!`,
      timestamp: new Date().toLocaleString('en-US', {
        month: 'short',
        day: '2-digit',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
      })
    }
  ],
  selectedAccount: '',
  selectedLogin: '',
  clearChat: false,
  status: 'idle',
  error: null,
  currentAccount: '',
  currentLogin: ''
};

// Thunk function payload interface
interface SendChatMessagePayload {
  account_id: string;
  login: string;
  message: string;
  name: string;
  type: 'bot' | 'user';
  key: string;
}

// Define thunk action with type annotations
export const sendChatMessageThunk = createAsyncThunk<
  any, // Replace with the correct type for response data if known
  SendChatMessagePayload,
  { rejectValue: string }
>(
  'chatbot/sendChatMessage',
  async (
    { account_id, login, message, name, type, key },
    { rejectWithValue }
  ) => {
    try {
      const token = localStorage.getItem('jwtToken');
      const response = await axios.post(
        `${AppConfig.apiUrl}/chat`,
        { account_id, login, message, name, type, key },
        {
          headers: {
            Authorization: `${token}`,
            'Content-Type': 'application/json'
          }
        }
      );

      return response.data;
    } catch (error: any) {
      const errorMessage = error.response?.data?.message || 'An error occurred';
      return rejectWithValue(errorMessage);
    }
  }
);

const chatbotSlice = createSlice({
  name: 'chatbot',
  initialState,
  reducers: {
    setCurrentAccount: (state, action: PayloadAction<string>) => {
      state.currentAccount = action.payload;
    },
    setCurrentLogin: (state, action: PayloadAction<string>) => {
      state.currentLogin = action.payload;
    },
    setSelectedAccount: (
      state,
      action: PayloadAction<{ accountId: string; login: string }>
    ) => {
      state.selectedAccount = action.payload.accountId;
      state.selectedLogin = action.payload.login;
    },
    addUserMessage: (state, action: PayloadAction<ChatMessage>) => {
      state.chatMessages.push(action.payload);
    },
    clearChatMessage: (state, action: PayloadAction<boolean>) => {
      if (action.payload) {
        state.chatMessages = [
          {
            type: 'bot',
            text: `🎉 Welcome to Fractal Alpha! 🎉\n\nYou've successfully created your account, and I'm here to help elevate your trading game.\nAs your AI trading assistant, I'm available 24/7 to provide insights, answer questions, and guide you through your trading journey.\nLet's achieve your goals together!`,
            timestamp: new Date().toLocaleString('en-US', {
              month: 'short',
              day: '2-digit',
              hour: 'numeric',
              minute: 'numeric',
              hour12: true
            })
          }
        ];
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(sendChatMessageThunk.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(sendChatMessageThunk.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.chatMessages.push({
          type: action.payload.type,
          text: action.payload.chat_reply,
          timestamp: new Date().toLocaleString('en-US', {
            month: 'short',
            day: '2-digit',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true
          })
        });
      })
      .addCase(sendChatMessageThunk.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload || 'An error occurred';
      });
  }
});

export const {
  setSelectedAccount,
  addUserMessage,
  clearChatMessage,
  setCurrentAccount,
  setCurrentLogin
} = chatbotSlice.actions;

export default chatbotSlice.reducer;
