/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, memo } from 'react';
import { Pagination } from 'antd';
import EditIcon from '../../assets/svgIcons/EditIcon';
import moment from 'moment';
import { forceUnaryOperator } from 'utils/numbers';
import { getFormattedDateTime } from 'utils/date';
import { TradeSide } from 'models/enums/tradeSide';
import { RestService } from 'services/restService';
import { RequestMethod } from 'models/enums/requestMethods';
import { Endpoints } from 'models/enums/endpoints';
import Loader from '../loader/loader';
import { debounce } from 'lodash';
import FileIcon from '../../assets/svgIcons/FileIcon';
import {
  setTradesData,
  getTradesData,
  getTradesTotal,
  setTradesTotal
} from '../../features/login/loginSlice';
import { useSelector, useDispatch } from 'react-redux';
import './styles.scss';

export enum FilterType {
  DATE = 'date',
  STRING = 'string',
  TRADE_TYPE = 'trade_type' // Add the new filter type
}

export type DateRange = {
  end: string;
  start: string;
};

export type FilterValue<T extends FilterType> = T extends FilterType.DATE
  ? DateRange
  : T extends FilterType.STRING
  ? string
  : T extends FilterType.TRADE_TYPE
  ? 'BUY' | 'SELL' // Add the type for trade type
  : never;

export type TradeFilters =
  | {
    [FilterType.DATE]?: DateRange;
    [FilterType.STRING]?: string;
    [FilterType.TRADE_TYPE]?: 'BUY' | 'SELL'; // Add the new filter type
  }
  | undefined;

const addLineBreakInDateTime = true;

type Props = {
  accountNo: string;
  appliedFilters?: TradeFilters;
  onRowPress: (trade: any) => void;
  totaltradeCount?: number;
};

const TradeTable = ({ accountNo, appliedFilters, onRowPress }: Props) => {
  const [trades, setTrades] = useState<{ isLoading: boolean; data: any[] }>({
    isLoading: true,
    data: []
  });
  // const [page, setPage] = useState({ page: 1, limit: 10 });
  const currentUrl = window.location.href;
  const urlParts = currentUrl.split('/');
  const homeIndex = urlParts.indexOf('overview');
  const homePart = homeIndex !== -1 ? urlParts[homeIndex] : null;
  const TradesData = useSelector(getTradesData);
  const totalCount = useSelector(getTradesTotal);
  const [page, setPage] = useState({ page: 1, limit: 10 });
  const dispatch = useDispatch();

  useEffect(() => {
    setPage({ page: 1, limit: 10 });
  }, [accountNo, appliedFilters]);

  useEffect(() => {
    const fetchTradesDebounced = debounce(fetchTrades, 400);
    fetchTradesDebounced(true);
    return () => {
      fetchTradesDebounced.cancel();
    };
  }, [accountNo, appliedFilters, page.page, page.limit]);

  const fetchTrades = async (isLoading) => {
    let token = localStorage.getItem('jwtToken');
    if (!accountNo || !token) return;
    try {
      const restService = new RestService();
      setTrades((prev) => ({ ...prev, isLoading: isLoading }));
      await new Promise((resolve) => setTimeout(resolve, 1000));

      const { trades, totalcount } = await restService.privateRequest({
        method: RequestMethod.GET,
        endpoint: `/${Endpoints.GET_TRADES}`,
        queryParams: {
          account_id: accountNo,
          page: page.page,
          page_size: page.limit,
          ...(appliedFilters?.[FilterType.DATE]
            ? {
              start_date: appliedFilters[FilterType.DATE]?.start,
              end_date: appliedFilters[FilterType.DATE]?.end
            }
            : {}),
          ...(appliedFilters?.[FilterType.STRING]
            ? {
              symbol_search: appliedFilters[FilterType.STRING]
            }
            : {}),
          ...(appliedFilters?.[FilterType.TRADE_TYPE]
            ? { type: appliedFilters[FilterType.TRADE_TYPE] }
            : {}) // Add trade type filter if it exists
        },
        accountSecrets: { jwt: token }
      });

      dispatch(setTradesData(trades));
      setTrades((prev) => ({ data: trades, isLoading: false }));
      dispatch(setTradesTotal(totalcount));
    } catch (er) {
      dispatch(setTradesData([]));
      dispatch(setTradesTotal(0));
      setTrades((prev) => ({ data: [], isLoading: false }));
    }
  };
  const calculateDuration = (startTime, endTime) => {
    const startMoment = moment(startTime);
    const endMoment = moment(endTime);
    const duration = moment.duration(endMoment.diff(startMoment));

    const months = endMoment.diff(startMoment, 'months');
    const days = duration.days();
    const hours = duration.hours();
    const minutes = duration.minutes();

    const components = [
      { value: months, unit: 'M' },
      { value: days, unit: 'D' },
      { value: hours, unit: 'h' },
      { value: minutes, unit: 'm' }
    ];

    const filteredComponents = components.filter(
      (component) => component.value !== 0
    );

    const formattedDuration = filteredComponents
      .map((component) => `${component.value} ${component.unit}`)
      .join(', ');

    return formattedDuration === '' ? '--' : formattedDuration;
  };

  return (
    <>
      <div className="table-responsive table-wrapper p-0">
        <table className="table m-0">
          <thead>
            <tr>
              <th>Open</th>
              <th>Symbol</th>
              <th>Close</th>
              <th>Entry Price</th>
              <th>Exit Price</th>
              <th>Type</th>
              <th>Volume</th>
              <th>Duration</th>
              <th>Net P & L</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {trades.isLoading ? (
              <tr>
                <td
                  colSpan={10}
                  style={{
                    textAlign: 'center',
                    height: '40vh',
                    position: 'relative'
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '100%'
                    }}
                  >
                    <Loader />
                  </div>
                </td>
              </tr>
            ) : (
              TradesData?.map((trade) => (
                <tr key={trade._id}>
                  <td>
                    {getFormattedDateTime(
                      trade.tradeTime,
                      addLineBreakInDateTime
                    )}
                  </td>
                  <td>{trade.symbol}</td>
                  <td>
                    {getFormattedDateTime(
                      trade.closingTime,
                      addLineBreakInDateTime
                    )}
                  </td>
                  <td>{trade.openPrice}</td>
                  <td>{trade.currentPrice}</td>
                  <td>
                    <span
                      className={trade.type === TradeSide.BUY ? 'buy' : 'sell'}
                    >
                      {trade.type}
                    </span>
                  </td>
                  <td>{trade.volume}</td>
                  <td>
                    {calculateDuration(trade.tradeTime, trade.closingTime)}
                  </td>
                  {/* <td className={trade.currentProfit > 0 ? 'green' : 'red'}>
                    {forceUnaryOperator(trade.currentProfit)}
                  </td> */}
                  <td className={trade?.currentProfit > 0 ? 'green' : 'red'}>
                    {trade?.currentProfit !== undefined &&
                      trade?.currentProfit !== null &&
                      !isNaN(parseFloat(trade.currentProfit))
                      ? `${trade.currentProfit < 0 ? '-$' : '$'}${Math.abs(trade.currentProfit).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
                      : 'N/A'}
                  </td>

                  <td>
                    {Object.keys(trade?.insights_details)?.length !== 0 ? (
                      <>
                        <a>
                          <FileIcon
                            className="icon"
                            onClick={() => {
                              if (homePart === 'overview') {
                                onRowPress(trade);
                              } else {
                                onRowPress(trade);
                              }
                            }}
                          />
                        </a>
                      </>
                    ) : (
                      <a>
                        <EditIcon
                          className="icon"
                          onClick={() => {
                            if (homePart === 'overview') {
                              onRowPress(trade);
                            } else {
                              onRowPress(trade);
                            }
                          }}
                        />
                      </a>
                    )}
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
        {TradesData?.length && !trades.isLoading ? (
          <div className="d-flex justify-content-between align-items-center p-3">
            <p className="m-0" style={{ whiteSpace: 'nowrap' }}>
              {(page.page - 1) * page.limit + 1} to{' '}
              {Math.min(page.page * page.limit)} of {totalCount} results
            </p>
            <Pagination
              total={totalCount}
              current={page.page}
              onChange={(newPage, pageSize) =>
                setPage({ page: newPage, limit: pageSize })
              }
              defaultPageSize={page.limit}
              defaultCurrent={1}
              className="table--pagination"
            />
          </div>
        ) : (
          <div className="blank__table__card">
            {!trades.isLoading && (
              <div className="blank__table__card__inner">No trades found</div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default memo(TradeTable);



