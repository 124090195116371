
import React, { useState, useEffect, useRef } from 'react';
import FractaLogo from '../../assets/images/fractal-logo-icon.svg';
import { selectUserName } from '../../features/login/loginSlice';
import { message, Progress } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { addUserMessage, sendChatMessageThunk } from './ChatSlice';
import UserChatIcon from '../../assets/images/user-chat-icon.svg';
import '../../features/fa-account/styles.scss';
import chatIcon from '../../assets/Img/chat-icon.svg';
import { useIsMobile } from '../../utils/helper';
import ChatbotWebTradePlan from './ChatbotWebTradePlan';



export default function ChatbotMobileTradePlan({
  from,
  selectedAccount,
  selectedLogin,
  activeAccounts
}) {
  const dispatch = useDispatch();
  const { chatMessages, currentAccount } = useSelector(
    (state) => state.chatbot
  );
  const { tradingPlan, newtradingPlan } = useSelector(
    (state) => state.tradingPlan
  );
  const [isDisabled, setIsDisabled] = useState(false);
  const userName = useSelector(selectUserName);
  const [chatMessage, setChatMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const textareaRef = useRef(null);
  const messagesEndRef = useRef(null);
  const isMobile = useIsMobile();
  const firstLetterOfUsername = userName
    ? userName.charAt(0).toUpperCase()
    : '';

  // Refs to store previous trading plan data
  const prevNewTradingPlan = useRef(newtradingPlan);
  const prevTradingPlan = useRef(tradingPlan);

  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current;
    textarea.style.height = 'auto'; // Reset the height
    textarea.style.height = textarea.scrollHeight + 'px'; // Set to the scroll height
  };

  useEffect(() => {
    if (textareaRef.current) {
      adjustTextareaHeight();
    }
  }, [chatMessage]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };




  const handleSendMessage = async () => {
    if (chatMessage.trim() !== '') {
      dispatch(
        addUserMessage({
          text: chatMessage,
          type: 'user',
          timestamp: new Date().toLocaleString('en-US', {
            month: 'short',
            day: '2-digit',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true
          })
        })
      );
      setChatMessage('');
      setIsLoading(true);
      try {
        await dispatch(
          sendChatMessageThunk({
            account_id: currentAccount,
            login: selectedLogin,
            message: chatMessage,
            name: userName,
            type: 'bot',
            key: ''
          })
        ).unwrap();
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        message.error('Failed to send message', 1);
      }
    }
  };

  const renderProgressBar = (currentValue, targetValue, isNegative) => {
    const percent = (currentValue / targetValue) * 100;
    const strokeColor = currentValue > targetValue ? '#ff2e48' : '#00c8b5';
    return (
      <Progress
        percent={percent}
        strokeLinecap="square"
        strokeColor={strokeColor}
        trailColor="#80e4da"
        showInfo={false}
      />
    );
  };


  return (
    <div>
      {from === 'account' && isMobile && (
      <div>
        {activeAccounts && activeAccounts.length > 0 && (
          <div className="chat-footer-dropdown" style={{ padding: 0 }}>
            <div className="d-flex align-items-center justify-content-between gap-3">
              <div>
                {activeAccounts.length === 1 ? (
                  <div style={{ color: 'white' }}>
                    Account #{activeAccounts[0].account_id}
                  </div>
                ) : (
                  <select
                    className="form-select"
                    value={selectedAccount?.account_id || ''}
                    onChange={handleSendMessage}
                    disabled={isDisabled}
                  >
                    <option value="">Please select fractal account</option>
                    {activeAccounts.map((account) => (
                      <option
                        key={account.account_id}
                        value={account.account_id}
                      >
                        Account #{account.account_id}
                      </option>
                    ))}
                  </select>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    )}
      {isMobile && (
        <button
          className="chat--icon"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasRight"
          aria-controls="offcanvasRight"
        >
          <img alt="chatIcon" src={chatIcon} />
        </button>
      )}
      <div
        className="offcanvas mobile-view-chatboat offcanvas-end offcanvasWidth"
        tabIndex="-1"
        id="offcanvasRight"
        aria-labelledby="offcanvasRightLabel"
      >
        <div className="offcanvas-header">
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body p-0">
          <ChatbotWebTradePlan
            from={'account'}
            selectedAccount={selectedAccount}
            selectedLogin={selectedLogin}
          
          />
        </div>
      </div>
    </div>
  );
}
