import React, { useState, useEffect } from 'react';
import BrandLogo from '../../assets/images/logo-white.png';
import BackArrow from '../../assets/images/arrow-back.svg';
import { useNavigate } from 'react-router-dom';
import Loader from 'components/loader/loader';
import AppConfig from '../../config';
import axios from 'axios';
import './styles.scss';

const PrivacyPolicy = (props) => {
  const navigate = useNavigate();
  const [privacyPolicy, setPrivacyPolicy] = useState('');
  const [loader, setLoader] = useState(true);

  const token = localStorage.getItem('jwtToken');

  useEffect(() => {
    axios
      .get(AppConfig.apiUrl + '/get_privacy_policy')
      .then((response) => {
        setLoader(false);
        setPrivacyPolicy(response?.data?.privacy_policy);
      })
      .catch((error) => {
        setLoader(false);
        console.error('There was an error fetching the privacy policy!', error);
      });
  }, []);

  const navigateBack = () => {
    navigate(-1);
  };

  return (
    <>
      <div className="header-main w-100">
        <div className="navbar navbar-expand-lg dark-bg">
          <div className="container-fluid">
            <div
              className="custom-brand"
              onClick={() => {
                if (!token) {
                  window.location.href = 'https://fractalalpha.com';
                } else {
                  navigate('/accounts');
                }
              }}
            >
              <img alt="BrandLogo" src={BrandLogo} className="brand-logo" />
            </div>
          </div>
        </div>
      </div>
      <div className="policy-section">
        <div className="container">
          <h1 className="policy-heading">
            <img
              alt="BackArrow"
              src={BackArrow}
              style={{
                maxWidth: '25px',
                cursor: 'pointer',
                marginRight: '10px'
              }}
              onClick={navigateBack}
            />
            Privacy Policy
          </h1>
          {loader ? (
            <Loader />
          ) : (
            <div className='policy-section'>
               <div dangerouslySetInnerHTML={{ __html: privacyPolicy }} />
            </div>
           
          )}
        </div>
      </div>
    </>
  );
};
export default PrivacyPolicy;
